import { dmpconnectFiltersActionConstants, dmpconnectUserActionConstants, newsActionConstants } from '../constants';
import { getSessionId } from '../helpers';

/**
 *
 * @param loginReferer
 * @returns {{loginReferer: *, type: string}}
 */
export const setLoginReferer = loginReferer => ({
  type: dmpconnectUserActionConstants.DMPC_SET_LOGIN_REFERER,
  loginReferer,
});

/**
 * @param searchParam
 * @param searchParamValue
 * @param ins // needed for refresh sagas
 * @param refresh // needed for refresh sagas
 * @returns {{refresh: boolean, ins: *, searchParam: *, type: string, searchParamValue: *}}
 */
export const setFindDocumentsParam = (searchParam, searchParamValue, ins, refresh = true) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAM,
  searchParam,
  searchParamValue,
  ins,
  refresh,
});

export const setFindDocumentsOpenedCategories = categories => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_OPENED_CATEGORIES,
  categories,
});

export const setFindDocumentsParamCategories = categories => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAM_CATEGORIES,
  categories,
});

export const resetFindDocumentsParams = () => ({
  type: dmpconnectUserActionConstants.DMPC_RESET_FIND_DOCUMENTS_PARAMS,
});

/**
 *
 * @param searchParams
 * @param ins // needed for refresh sagas
 * @param refresh // needed for refresh sagas
 * @returns {{refresh: boolean, ins: *, type: string, searchParams: *}}
 */
export const setFindDocumentsParams = (searchParams, ins, refresh = true) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAMS,
  searchParams,
  ins,
  refresh,
});

/**
 *
 * @param findPatientsParams
 * @returns {{ins: *, searchParam: *, type: string, searchParamValue: *}}
 */
export const setFindPatientsParams = findPatientsParams => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_PATIENTS_PARAMS,
  findPatientsParams,
});

export const setFindPatientsIns = ins => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FIND_PATIENTS_INS,
  ins,
});


/**
 *
 * @returns {{type: string}}
 */
export const clearLoginReferer = () => ({
  type: dmpconnectUserActionConstants.DMPC_CLEAR_LOGIN_REFERER,
});

/**
 *
 * @param pinCode
 * @param serverName
 * @param practiceSetting
 * @param cpsPracticeLocationIndice
 * @returns {{practiceSetting: *, pinCode: *, serverName: *, type: string, cpsPracticeLocationIndice: *}}
 */
export const login = (pinCode, practiceSetting, cpsPracticeLocationIndice, auto = false) => ({
  type: dmpconnectUserActionConstants.DMPC_LOGIN,
  pinCode,
  practiceSetting,
  cpsPracticeLocationIndice,
  auto,
});

/**
 *
 * @returns {function(*, *): *}
 */
export const logout = redirectTo => (dispatch, getState) => dispatch(
  {
    type: dmpconnectUserActionConstants.DMPC_LOGOUT,
    sessionId: getSessionId(getState()),
    redirectTo,
  },
);

export const requestEsLogout = () => (dispatch, getState) => dispatch(
  {
    type: dmpconnectUserActionConstants.DMPC_ES_LOGOUT,
    sessionId: getSessionId(getState()),
  },
);

/**
 *
 * @returns {{type: string}}
 */
export const logoutSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 */
export const loginSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS,
});

export const setForceSecretConnexion = (ins, force) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_FORCE_SECRET_CONNEXION,
  ins,
  force,
});

export const removeDocumentFromCache = (uniqueUUid, ins) => ({
  type: dmpconnectUserActionConstants.DMPC_REMOVE_DOCUMENT_FROM_CACHE,
  uniqueUUid,
  ins,
});

export const saveParsedHr = (ins, documentId, parsedHr) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_HR,
  ins,
  documentId,
  parsedHr,
});
export const initDocumentCache = () => ({
  type: dmpconnectUserActionConstants.DMPC_INIT_DOCUMENTS_CACHE,
});
export const emptyDocumentCache = (ins = null) => ({
  type: dmpconnectUserActionConstants.DMPC_EMPTY_DOCUMENTS_CACHE,
  ins,
});
export const unsetNewDocumentStatus = (ins, uniqueId) => ({
  type: dmpconnectUserActionConstants.DMPC_UNSET_NEW_DOCUMENT_STATUS,
  ins,
  uniqueId,
});
export const markAllDocsAsRead = ins => ({
  type: dmpconnectUserActionConstants.DMPC_MARK_ALL_DOCS_AS_READ,
  ins,
});

export const setLoginStepTwo = status => ({
  type: dmpconnectUserActionConstants.DMPC_SET_LOGIN_STEP_TWO,
  status,
});

export const setLstDocsSortedColumn = (column, desc) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_LSTDOCS_SORTED_COLUMN,
  column,
  desc,
});

export const esLogin = (userLogin, userPassword) => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN,
  userLogin,
  userPassword,
});
export const setEsUser = user => ({
  type: dmpconnectUserActionConstants.DMPC_SET_ES_USER,
  user,
});


export const setUserJWT = token => ({
  type: dmpconnectUserActionConstants.DMPC_SET_USER_JWT,
  token,
});

export const esLoginFailed = message => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN_FAILED,
  message,
});
export const esLoginSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS,
});

export const setUserFilter = (psId, filterKey, filterValue) => ({
  type: dmpconnectFiltersActionConstants.DMPC_SET_USER_FILTER,
  psId,
  filterKey,
  filterValue,
});

export const setCurrentFilter = (filterKey, filterValue) => ({
  type: dmpconnectFiltersActionConstants.DMPC_SET_CURRENT_FILTER,
  filterKey,
  filterValue,
});

export const setUserNews = (psId, news) => ({
  type: newsActionConstants.DMPC_SET_USER_NEWS,
  psId,
  news,
});

export const setPinCode = pinCode => ({
  type: dmpconnectUserActionConstants.SET_PIN_CODE,
  pinCode,
});

export const setExpiredSession = expiredSession => ({
  type: dmpconnectUserActionConstants.EXPIRED_SESSION,
  expiredSession,
});

export const setRemovedCPX = removedCPX => ({
  type: dmpconnectUserActionConstants.REMOVED_CPX,
  removedCPX,
});

export const updateDocumentNextVersion = (uniqueUUid, nextVersionUuid, status, ins) => ({
  type: dmpconnectUserActionConstants.DMPC_UPDATE_DOCUMENT_NEXT_VERSION,
  uniqueUUid,
  nextVersionUuid,
  status,
  ins,
});
