import { dmpconnectInitActionConstants, mssActionConstants } from 'dmpconnectjsapp-base/constants';
import {
  dmpconnectAccessibleDmpListActionsConstants,
  dmpconnectApplicationActionConstants, mssActions,
} from '../constants';


export const initApplication = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION,
});

export const initApplicationSuccess = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION_SUCCESS,
});

export const initApplicationFailure = (error = null) => ({
  type: dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION_FAILURE,
  error,
});

export const resetApplication = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_RESET_APPLICATION,
});

export const setModalConfirmation = modalConfirmation => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_CONFIRMATION,
  modalConfirmation,
});
export const setModalInformation = information => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_INFORMATION,
  information,
});
export const setModalError = modalError => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR,
  modalError,
});

export const clearModalError = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR,
});

export const setDmpSearchPanelOpened = panel => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_DMP_SEARCH_PANEL_OPENED,
  panel,
});

export const setInteropCodes = codes => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_INTEROP_CODES,
  codes,
});

export const setDmpParameters = params => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_DMP_PARAMETERS,
  params,
});
export const setConnectorKo = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_CONNECTOR_KO,
});

export const setSessionKo = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_SESSION_KO,
});

export const setConnectorOk = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_CONNECTOR_OK,
});
export const setConnectorConfigOk = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_CONNECTOR_CONFIG_OK,
});
export const setConnectorConfigKo = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_CONNECTOR_CONFIG_KO,
});

export const setSessionOk = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_SESSION_OK,
});

export const setConnectorVersionKo = version => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_CONNECTOR_VERSION_KO,
  version,
});

export const setLicenseStatus = (status, error) => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_LICENSE_STATUS,
  status,
  error,
});

export const startVirtualPrinter = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_START_VIRTUAL_PRINTER,
});

export const dashboardInit = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT,
});

export const setDashboardInitOk = (status = true) => ({
  type: dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT_OK,
  status,
});

export const monitorLicenseAfterForm = () => ({
  type: dmpconnectInitActionConstants.DMPC_LICENSE_CHECK,
});
export const setLicenseAfterFormOK = () => ({
  type: dmpconnectInitActionConstants.DMPC_LICENSE_AFTER_FORM_OK,
});

export const setALDPopupOpened = (opened, vitaleIndex = -1, ins = '-1') => ({
  type: dmpconnectApplicationActionConstants.DMPC_ALD_POPUP_OPENED,
  opened,
  vitaleIndex,
  ins,
});

export const setDpPopupOpened = (opened, vitaleIndex = -1, ins = '-1') => ({
  type: dmpconnectApplicationActionConstants.DMPC_DP_POPUP_OPENED,
  opened,
  vitaleIndex,
  ins,
});

export const readVitaleProcess = (sessionId, sectionName) => ({
  type: dmpconnectApplicationActionConstants.DMPC_READ_VITALE_PROCESS,
  sessionId,
  sectionName,
});

export const setShowMssPopup = show => ({
  type: dmpconnectApplicationActionConstants.DMPC_MSS_POPUP_OPENED,
  show,
});

export const setMssEmailContent = content => ({
  type: dmpconnectApplicationActionConstants.DMPC_MSS_SET_EMAIL_CONTENT,
  content,
});
export const resetMssEmailContent = forcedIns => ({
  type: dmpconnectApplicationActionConstants.DMPC_MSS_RESET_EMAIL_CONTENT,
  forcedIns,
});


export const addMssEmailAttachment = document => ({
  type: dmpconnectApplicationActionConstants.DMPC_MSS_ADD_EMAIL_ATTACHEMENT,
  document,
});

export const setMssDownloadedAttachment = attachment => ({
  type: mssActionConstants.SET_ATTACHMENT_DOWNLOADED,
  attachment,
});
export const downloadAllAttachmentsZipped = (attachments, messageId, mssEmail, subject) => ({
  type: mssActions.DOWNLOAD_ALL_ATTACHMENTS_ZIPPED,
  attachments,
  messageId,
  mssEmail,
  subject,
});

export const setMSSSearchedMessagesIds = (mssEmail, messageIds) => ({
  type: mssActions.SET_MSS_SEARCHED_MESSAGE_IDS,
  messageIds,
  mssEmail,
});

export const deleteMssFolder = (mssEmail, folder, mssApiType, toastId) => ({
  type: mssActions.DELETE_MSS_FOLDER,
  folder,
  mssEmail,
  mssApiType,
  toastId,
});

export const setSaasTokenValidated = validated => ({
  type: dmpconnectApplicationActionConstants.DMPC_SAAS_TOKEN_VALIDATED,
  validated,
});


export const showUploadDocumentModal = (show, document = undefined) => ({
  type: dmpconnectApplicationActionConstants.DMPC_SHOW_UPLOAD_DOCUMENT_MODAL,
  show,
  document,
});

export const updateAvailableDmpLastAccessDate = (ins, performer) => ({
  type: dmpconnectAccessibleDmpListActionsConstants.UPDATE_LAST_ACCESS,
  ins,
  performer,
});

export const setBackPage = (backType, backPage) => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_BACK_PAGE,
  backPage,
  backType,
});
export const setVitaleCardSerialNumber = serialNumber => ({
  type: dmpconnectApplicationActionConstants.SET_VITALE_CARD_SERIAL_NUMBER,
  serialNumber,
});
export const updateCpxAccessToken = token => ({
  type: dmpconnectApplicationActionConstants.DMPC_UPDATE_CPX_ACCESS_TOKEN,
  token,
});

export const importAvailableDMPList = (list, psId) => ({
  type: dmpconnectAccessibleDmpListActionsConstants.IMPORT_ACCESSIBLE_DMPS_LIST,
  list,
  psId,
});

export const setApiLoginCheckValues = values => ({
  type: dmpconnectApplicationActionConstants.DMPC_API_LOGIN_CHECK_VALUES,
  values,
});
export const sendMssWebEmail = (emailContent, esUser) => ({
  type: mssActionConstants.SEND_WEB_EMAIL,
  emailContent,
  esUser,
});

export const setUrlProcessed = processed => ({
  type: dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED,
  processed,
});

export const associateInsiIdentity = (ins, identity, performer) => ({
  type: dmpconnectAccessibleDmpListActionsConstants.ASSOCIATE_INSI_IDENTITY,
  ins,
  identity,
  performer,
});
