import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export default function InformationPrompt({
  title,
  message,
  handleClose,
  show,
  errorDisplay,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable
    >
      <Modal.Header closeButton className={errorDisplay && 'bg-danger'}>
        <Modal.Title as="h5" className={errorDisplay && 'text-white'}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="typography-big-text">{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={handleClose}
        >
          Accéder au document
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

InformationPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorDisplay: PropTypes.bool,
};

InformationPrompt.defaultProps = {
  errorDisplay: false,
};
