import { mssReceiptNotificationTypes } from 'dmpconnectjsapp-base/constants';
import { b64DecodeUnicode } from '../../utils/dataUtils';
import {
  extractAdditionalAuthors,
  extractEventCodes,
  extractInformants,
  extractIntendedRecipients, extractPerformer,
  extractTreatingPhysician,
} from './cdaData';
import { calculateVisibility } from '../../rules/documentRules';
import { extractInsiIdentity } from './insi';

function extractAttachment(attachment) {
  const parsedEventCodes = extractEventCodes(attachment);
  const parsedAdditionalAuthors = extractAdditionalAuthors(attachment);
  const parsedInformants = extractInformants(attachment);
  const parsedIntendedRecipients = extractIntendedRecipients(attachment);

  const {
    patientIns: { value: patientIns = '' } = {},
    uniqueId: { value: uniqueId = '' } = {},
    fileContentInBase64: { value: fileContentInBase64 = '' } = {},
    documentTitle: { value: documentTitle = '' } = {},
    documentDescription: { value: documentDescription = '' } = {},
    documentCategory: { value: documentCategory = '' } = {},
    documentFormat: { value: documentFormat = '' } = {},
    healthcareSetting: { value: healthcareSetting = '' } = {},
    versionNumber: { value: versionNumber = '' } = {},
    setIdRoot: { value: setIdRoot = '' } = {},
    setIdExtension: { value: setIdExtension = '' } = {},
    replacedDocumentUniqueId: { value: replacedDocumentUniqueId = '' } = {},
    invisiblePatient: { value: invisiblePatient } = {},
    masquePS: { value: masquePS } = {},
    invisibleRepresentantLegal: { value: invisibleRepresentant } = {},
  } = attachment;

  return {
    patientIns,
    uniqueId,
    fileContentInBase64,
    documentTitle,
    documentDescription,
    documentCategory,
    documentFormat: Number(documentFormat),
    healthcareSetting,
    versionNumber,
    setIdRoot,
    setIdExtension,
    replacedDocumentUniqueId,
    treatingPhysician: extractTreatingPhysician(attachment),
    performer: extractPerformer(attachment),
    additionalAuthors: parsedAdditionalAuthors && parsedAdditionalAuthors.length > 0
      ? parsedAdditionalAuthors
      : undefined,
    intendedRecipients: parsedIntendedRecipients && parsedIntendedRecipients.length > 0
      ? parsedIntendedRecipients
      : undefined,
    informants: parsedInformants && parsedInformants.length > 0
      ? parsedInformants
      : undefined,
    eventCodes: parsedEventCodes,
    invisiblePatient,
    masquePS,
    invisibleRepresentant,
    documentVisibility: calculateVisibility(
      invisiblePatient,
      masquePS,
      invisibleRepresentant,
      true,
    ),
  };
}

export const extractSendMssMessageParams = (data) => {
  try {
    const {
      recipient,
      cc,
      bcc,
      sendInBackground: {
        value: sendInBackground,
      } = {},
      senderWording: {
        value: senderWording,
      } = {},
      replyTo: {
        value: replyTo,
      } = {},
      messageId: {
        value: messageId,
      } = {},
      inReplyToMessageIds: {
        value: inReplyToMessageIds,
      } = {},
      references: {
        value: references,
      } = {},
      title: {
        value: title,
      } = {},
      content: {
        value: content,
      } = {},
      attachments,
      rawAttachments,
      returnReceiptTo: {
        value: returnReceiptTo,
      } = {},
      dispositionNotificationTo: {
        value: dispositionNotificationTo,
      } = {},
    } = data;

    let recipients;
    if (recipient && recipient.email) {
      if (!Array.isArray(recipient.email)) {
        recipients = [recipient.email];
      } else {
        recipients = recipient.email;
      }
    }
    let ccs;
    if (cc && cc.email) {
      if (!Array.isArray(cc.email)) {
        ccs = [cc.email];
      } else {
        ccs = cc.email;
      }
    }
    let bccs;
    if (bcc && bcc.email) {
      if (!Array.isArray(bcc.email)) {
        bccs = [bcc.email];
      } else {
        bccs = bcc.email;
      }
    }

    let attachmentArray;
    if (attachments && attachments.attachment) {
      if (!Array.isArray(attachments.attachment)) {
        attachmentArray = [attachments.attachment];
      } else {
        attachmentArray = attachments.attachment;
      }
    }

    let rawAttachmentArray;
    if (rawAttachments && rawAttachments.rawAttachment) {
      if (!Array.isArray(rawAttachments.rawAttachment)) {
        rawAttachmentArray = [rawAttachments.rawAttachment];
      } else {
        rawAttachmentArray = rawAttachments.rawAttachment;
      }
    }

    let notificationReceiver;
    let notificationReceiversType;
    if (returnReceiptTo) {
      notificationReceiver = returnReceiptTo;
      notificationReceiversType = mssReceiptNotificationTypes.DSN;
    }
    if (dispositionNotificationTo) {
      notificationReceiver = dispositionNotificationTo;
      notificationReceiversType = mssReceiptNotificationTypes.MDN;
    }
    let insiIdentity;
    if (data.patient) {
      insiIdentity = extractInsiIdentity(data.patient);
    }

    return {
      sendInBackground,
      fromRemoteControl: true,
      senderWording,
      replyTo,
      messageId,
      inReplyToMessageIds,
      references,
      recipients: recipients.map(email => email.value).join(';'),
      cc: ccs ? ccs.map(email => email.value).join(';') : '',
      bcc: bccs ? bccs.map(email => email.value).join(';') : '',
      title,
      // replace unicode control chars
      messageContent: content ? b64DecodeUnicode(content).replace(/\p{C}/gu, '') : '',
      notificationReceiver,
      notificationReceiversType,
      attachments: attachmentArray
        ? attachmentArray.map(attachment => extractAttachment(attachment))
        : [],
      otherAttachments: rawAttachmentArray
        ? rawAttachmentArray.map(attachment => ({
          contentType: attachment.contentType.value,
          fileContentInBase64: attachment.fileContentInBase64.value,
          documentTitle: attachment.documentTitle.value,
        }))
        : [],
      insiIdentity,
    };
  } catch (e) {
    console.log(e);
    return false;
  }
};
