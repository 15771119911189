import gte from 'semver/functions/gte';
import gt from 'semver/functions/gt';

export const checkDMPConnectJSVersion = (version, minimumVersion) => gte(version, minimumVersion);
export const checkDMPConnectJSVersiongt = (version, minimumVersion) => gt(version, minimumVersion);
export const formatVersion = (version) => {
  const [major, minor, patch, beta] = version.split('.');

  return `${major}.${minor || 0}.${patch || 0}${beta ? `-beta${beta}` : ''}`;
};
