export const extractGetCertifiedIdentityParams = (data) => {
  try {
    const {
      patient: {
        nir: {
          '@root': root,
          '@extension': extension,
        },
        birthDate: {
          value: birthDate,
        },
        birthRank: {
          value: birthRank,
        },
      },
    } = data;

    return {
      insType: root,
      nirOD: extension + root,
      birthDate,
      birthRank,
    };
  }
  catch (e) {
    return false;
  }
};

export const generateGetCertifiedIdentityResponse = (requestId, certifiedIdentity) => {
  const {
    Ins: {
      s_ins: ins,
      s_insType: insType,
    },
    s_birthday: birthDay,
    s_patientGivenName: givenName,
    s_patientName: name,
  } = certifiedIdentity;

  return {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    Ins: {
      '@root': insType,
      '@extension': ins,
    },
    birthDay,
    givenName,
    name,
  };
};
