import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse';
import { getCurrentPathname } from 'dmpconnectjsapp-base/helpers/accessors';
import { push } from 'connected-react-router';
import { clearModalError } from '../../dmpconnect/actions';
import ErrorDetails from '../Common/Message/ErrorDetails';
import { getErrorDescription, getErrorMessage } from '../../dmpconnect/errors';
import { errorActions, errorActionsMessages, executeErrorAction } from '../../dmpconnect/errors/errorActions';
import { errorSeverities } from '../../dmpconnect/errors/errorConfiguration';

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
  }

  getErrorDescription(modalError) {
    if (modalError) {
      return getErrorDescription(modalError.error);
    }
    return null;
  }

  getHeaderClass(errorDescription) {
    return errorDescription.severity === errorSeverities.HIGH_SEVERITY ? 'bg-danger' : 'bg-warning';
  }

  handleClose() {
    const { dispatch, modalError } = this.props;
    if (modalError.forceReconnect === true) {
      dispatch(push('/'));
    } else {
      const errorDescription = this.getErrorDescription(modalError);
      if (errorDescription === null) return;
      executeErrorAction(errorDescription, dispatch);
    }
    dispatch(clearModalError());
  }

  toggleDetails() {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  }

  render() {
    const { modalError } = this.props;
    const { showDetails } = this.state;
    // const show = modalError !== null;
    const errorDescription = this.getErrorDescription(modalError);
    const showDetailsToggleClass = showDetails ? 'dropup' : 'dropdown';
    return (
      <>
        {modalError
        && (
          <Modal
            className="error-modal"
            show
            onHide={() => this.handleClose()}
            centered
            backdrop="static"
          >
            <Modal.Header className={this.getHeaderClass(errorDescription)} closeButton>
              <Modal.Title as="h5" className="text-white">{errorDescription.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span style={{ whiteSpace: 'pre-wrap' }}>{getErrorMessage(modalError.error)}</span>
              {errorDescription.showDetails
              && (
                <div>
                  <div className="d-flex">
                    <div className={`btn-group ml-auto ${showDetailsToggleClass} `}>
                      <button
                        type="button"
                        className="btn btn-outline dropdown-toggle ml-auto"
                        onClick={() => this.toggleDetails()}
                      >
                        Détails
                      </button>
                    </div>
                  </div>
                  <Collapse in={showDetails}>
                    <div className="mt-1 overflow-auto" style={{ width: '100%', height: '0px' }}>
                      <ErrorDetails
                        details={modalError.details}
                      />
                    </div>
                  </Collapse>
                </div>
              )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => this.handleClose()}>
                {modalError.forceReconnect === true ? (
                  errorActionsMessages[errorActions.CLOSE_SESSION]
                ) : (
                  errorActionsMessages[errorDescription.action]
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

ErrorModal.propTypes = {
  modalError: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  modalError: {},
};

function mapStateToProps(state) {
  const { dmpconnectApplication: { modalError } } = state;
  return {
    currentPathName: getCurrentPathname(state),
    modalError,
  };
}

const connectedErrorModal = connect(mapStateToProps)(ErrorModal);

export default connectedErrorModal;
