import mergeWith from 'lodash.mergewith';
import moment from 'moment';
import { remoteDocumentFormats } from '../../constants/remoteConstants';
import { uploadType } from '../sendDocument';
import {
  extractAdditionalAuthors,
  extractEventCodes,
  extractInformants,
  extractIntendedRecipients,
  extractPerformer,
  extractTreatingPhysician,
} from './cdaData';
import { extractInsiIdentity } from './insi';
import { insOidToType } from '../../constants/dmpConstants';


const defaulSubmitDocumentParams = {
  patient: {
    ins: {
      '@root': '',
      '@extension': '',
    },
  },
  document: {
    content: '',
    format: '',
    typeCode: '',
    practice: '',
    role: '',
    title: '',
    description: '',
    invisiblePatient: false,
    masquePS: false,
    invisibleRepresentantLegal: false,
  },
};

const formatSubmitDocumentParams = params => ({
  ins: params.ins,
  title: params.title,
  description: params.description ? params.description : '',
  contribution: '',
  filename: params.title,
  base64: params.content,
  documentFormat: params.format ? remoteDocumentFormats[params.format] : '',
  formats: [],
  type: uploadType.fileUpload,
  patientHidden: params.invisiblePatient,
  guardianHidden: params.invisibleRepresentant,
  healthcareProfessionalHidden: params.masquePS,
  healthcareSetting: params.practice ? params.practice : '',
  typeCode: params.typeCode ? params.typeCode : '',
  replaceDocumentId: params.replacedDocumentUniqueId,
  versionNumber: params.versionNumber,
  setIdRoot: params.setIdRoot,
  setIdExtension: params.setIdExtension,
  creationDate: params.creationDate ? moment(params.creationDate, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD']).format('DD/MM/YYYY') : undefined,
  serviceStartDate: params.serviceStartDate ? moment(params.serviceStartDate, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD']).format('DD/MM/YYYY') : undefined,
  serviceStopDate: params.serviceStopDate ? moment(params.serviceStopDate, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD']).format('DD/MM/YYYY') : undefined,
  uniqueId: params.uniqueId,
});

export const checkIfSubmitDocumentParamsValid = params => (
  params.ins !== ''
    && params.base64 !== ''
    && params.format !== ''
    && params.typeCode !== ''
    && params.healthcareSetting !== ''
);

export const extractSubmitDocumentParams = (document) => {
  const params = mergeWith(
    {}, defaulSubmitDocumentParams, document,
    (a, b) => (b === null ? a : undefined),
  );

  let insType;
  let ins;

  const {
    patient: {
      ins: {
        '@root': insRoot,
        '@extension': insExtension,
      },
    },
    sendInBackground: {
      value: sendInBackground,
    } = {},
    document: {
      replacedDocumentUniqueId: {
        value: replacedDocumentUniqueId,
      } = {},
      uniqueId: {
        value: uniqueId,
      } = {},
      versionNumber: {
        value: versionNumber,
      } = {},
      setIdRoot: {
        value: setIdRoot,
      } = {},
      setIdExtension: {
        value: setIdExtension,
      } = {},
      retrieveDocumentUuid: {
        value: retrieveDocumentUuid,
      } = {},
      content: {
        value: content,
      } = {},
      format: {
        value: format,
      } = {},
      typeCode: {
        value: typeCode,
      } = {},
      creationDate: {
        value: creationDate,
      } = {},
      serviceStartDate: {
        value: serviceStartDate,
      } = {},
      serviceStopDate: {
        value: serviceStopDate,
      } = {},
      practice: {
        value: practice,
      } = {},
      role: {
        value: role,
      } = {},
      title: {
        value: title,
      } = {},
      description: {
        value: description,
      } = {},
      invisiblePatient: {
        value: invisiblePatient,
      } = {},
      masquePS: {
        value: masquePS,
      } = {},
      invisibleRepresentantLegal: {
        value: invisibleRepresentant,
      } = {},
    },
  } = params;

  const parsedEventCodes = extractEventCodes(params.document);
  const parsedAdditionalAuthors = extractAdditionalAuthors(params.document);
  const parsedInformants = extractInformants(params.document);
  const parsedIntendedRecipients = extractIntendedRecipients(params.document);
  const insiIdentity = extractInsiIdentity(params.patient);

  if (insiIdentity && insiIdentity.Ins) {
    const { s_oid, s_value, s_key } = insiIdentity.Ins;
    insType = insOidToType[s_oid];
    ins = `${s_value}${s_key}${insType}`;
  } else {
    insType = insRoot;
    ins = insExtension + insRoot;
  }

  return {
    params: {
      sendInBackground,
      ...formatSubmitDocumentParams({
        ins,
        content,
        format,
        typeCode,
        practice,
        role,
        title,
        description,
        invisiblePatient: ['true', '1', 1].includes(invisiblePatient),
        masquePS: ['true', '1', 1].includes(masquePS),
        invisibleRepresentant: ['true', '1', 1].includes(invisibleRepresentant),
        replacedDocumentUniqueId,
        creationDate,
        serviceStartDate,
        serviceStopDate,
        versionNumber,
        setIdRoot,
        setIdExtension,
        uniqueId,
      }),
      treatingPhysician: extractTreatingPhysician(params.document),
      performer: extractPerformer(params.document),
      additionalAuthors: parsedAdditionalAuthors && parsedAdditionalAuthors.length > 0
        ? parsedAdditionalAuthors
        : undefined,
      intendedRecipients: parsedIntendedRecipients && parsedIntendedRecipients.length > 0
        ? parsedIntendedRecipients
        : undefined,
      informants: parsedInformants && parsedInformants.length > 0
        ? parsedInformants
        : undefined,
      retrieveDocumentUuid: Number(retrieveDocumentUuid) === 1,
      eventCodes: parsedEventCodes,
      Identity: insiIdentity,
    },
    insType,
    visibility: {
      invisiblePatient: ['true', '1', 1].includes(invisiblePatient),
      masquePS: ['true', '1', 1].includes(masquePS),
      invisibleRepresentant: ['true', '1', 1].includes(invisibleRepresentant),
    },
  };
};
