import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

export default function ErrorDetails({ details }) {
  const errorDetails = details.reduce((result, detail) => ({ ...result, [detail.name]: detail.content }), {});

  return (
    <ReactJson
      src={errorDetails}
      name="Details"
      collapsed={2}
      displayObjectSize={false}
      displayDataTypes={false}
    />
  );
}

ErrorDetails.propTypes = {
  details: PropTypes.array.isRequired,
};
