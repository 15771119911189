export const extractDeleteDocumentParams = (params) => {
  const {
    patient: {
      ins: {
        '@root': insRoot,
        '@extension': insExtension,
      },
    },
    document: {
      uniqueId: {
        value: uniqueId,
      },
      uuid: {
        value: uuid,
      } = {},
      practice: {
        value: practice,
      } = {},
    },
  } = params;

  return {
    ins: insExtension + insRoot,
    uniqueId,
    uuid,
    practice,
  };
};
