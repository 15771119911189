import mergeWith from 'lodash/mergeWith';

import { shortGenders, shortSexes } from '../../constants/dmpConstants';
import { remoteSexes } from '../../constants/remoteConstants';

const formatFindDocumentsRemoteParams = values => ({
  s_name: values.name ? values.name : '',
  s_givenName: values.givenName ? values.givenName : '',
  s_city: values.city ? values.city : '',
  s_postalCode: values.postalCode ? values.postalCode : '',
  s_birthday: values.birthday,
  i_sex: values.sex ? remoteSexes[values.sex] : 1,
  i_approxName: ['true', '1', 1].includes(values.approxName),
  i_approxCity: ['true', '1', 1].includes(values.approxCity),
});

const defaultFindDmpParams = {
  filters: {
    name: {
      '@approximate': false,
      family: {
        value: '',
      },
      given: {
        value: '',
      },
    },
    gender: {
      value: 'U',
    },
    birthday: {
      value: '',
    },
    address: {
      '@approximate': false,
      postalCode: {
        value: '',
      },
      city: {
        value: '',
      },
    },
  },
};

export const extractFindDmpParams = (filters) => {
  const params = mergeWith(
    {}, defaultFindDmpParams, filters,
    (a, b) => (b === null ? a : undefined),
  );

  const {
    filters: {
      name: {
        '@approximate': approxName = 0,
        family: {
          value: name = '',
        } = {},
        given: {
          value: givenName = '',
        } = {},
      } = {},
      gender: {
        value: sex = '',
      } = {},
      birthday: {
        value: birthday = '',
      } = {},
      address: {
        '@approximate': approxCity = 0,
        postalCode: {
          value: postalCode = '',
        } = {},
        city: {
          value: city = '',
        } = {},
      } = {},
    },
  } = params;

  return formatFindDocumentsRemoteParams({
    name,
    givenName,
    city,
    postalCode,
    birthday,
    sex,
    approxName,
    approxCity,
  });
};

export const generateFindDmpResponse = (requestId, patients) => {
  const formattedPatients = patients.map((patient) => {
    const {
      CivilStatus: { i_gender, s_given, s_name },
      Ins: { s_ins, s_insType },
      i_matchingPercentage,
      i_sex,
      s_birthDay,
      s_birthName,
      s_city,
      s_postalCode,
    } = patient;

    return {
      '@matching': `${Number(i_matchingPercentage)}%`,
      ins: {
        '@extension': s_ins,
        '@root': s_insType,
      },
      gender: i_gender ? shortGenders[i_gender] : '',
      usualName: s_name,
      birthName: s_birthName,
      given: s_given,
      sex: shortSexes[i_sex],
      birthday: s_birthDay,
      address: {
        postalCode: s_postalCode,
        city: s_city,
      },
    };
  });

  return {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    dmp: [
      ...formattedPatients,
    ],
  };
};
