import React from 'react';
import * as yup from 'yup';
import {
  call, put, select, take,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import {
  clearModalError,
  clearSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  logoutSuccess,
  setESConfiguration,
  setPersistedConnectorConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatFindPatientsParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import {
  getConfigurationValue,
  getCurrentPathname, getDmpconnectPersistedConnectorConfig,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps, isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { toast } from 'react-toastify';
import moment from 'moment';
import { generateAndSaveToken } from 'dmpconnectjsapp-base/sagas/utilsSagas';
import base64 from 'base-64';
import mergeWith from 'lodash.mergewith';
import { checkIfUserIsLoggedIn } from './securitySagas';
import {
  b64DecodeUnicode, isUrlValid, mergeSearchAndHash, parseQueryString,
} from '../utils/dataUtils';
import {
  clearLoginReferer,
  getAction,
  getDirectAuthenticationDMPStatus, resetMssEmailContent,
  selectINS, setApiLoginCheckValues,
  setDmpSearchPanelOpened, setFindPatientsIns,
  setFindPatientsParams,
  setModalError, setMssEmailContent,
  setPersistedAppConfiguration, setSaasTokenValidated, setShowMssPopup, setTseConfiguration, setUrlProcessed,
} from '../actions';
import { createError, createErrorDetails, createModalError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import {
  availableAirStrongAuthModes, dmpconnectHashes, dmpStatuses, searchPanels,
} from '../constants';
import { findPatientsDefaultValues, findPatientsParamsSchema } from '../helpers/searchUtils';
import {
  getDmpconnectESConfiguration,
  getDmpconnectPersistedConfiguration, getDmpLandingPage,
  getDocumentRedirectUrl, getSaasTokenConfig,
  isInsValid,
} from '../helpers';
import { getAllDocsFromCache } from './utilsSagas';
import { getLastHR, getLastVSM } from '../helpers/documentUtils';
import env from '../../envVariables';
import { getEsUserAuthenticationContext, getEsUserAuthenticationMode } from '../rules/airRules';
import { getUniqueUUid } from '../rules/documentRules';


const handleLoginCheck = function* (logincheck) {
  const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
  const loginCheckOK = logincheck && requiredValues.every(val => val in logincheck);

  if (loginCheckOK) {
    yield put(setPersistedAppConfiguration('logincheck', { ...logincheck }));
  } else {
    // show error modal
    const error = createError(errorTypes.SoftwareErrors, softwareErrors.LOGINCHECK_MISSING_VALUE);
    const details = [
      createErrorDetails('provided', logincheck),
      createErrorDetails('required', { properties: requiredValues.join(', ') }),
    ];
    const modalError = createModalError(error, details);
    yield put(setModalError(modalError));
  }
};

const handleApiLoginCheck = function* (values) {
  const { psInternalId, patientInternalId } = values;

  if (psInternalId && patientInternalId) {
    yield put(setApiLoginCheckValues({ psInternalId, patientInternalId }));
  } else {
    // show error modal
    const error = createError(errorTypes.SoftwareErrors, softwareErrors.LOGINCHECK_MISSING_VALUE);
    const details = [
      createErrorDetails('provided', values),
      createErrorDetails('required', { psInternalId: 'xxxxx', patientInternalId: 'yyyyy' }),
    ];
    const modalError = createModalError(error, details);
    yield put(setModalError(modalError));
  }
};

const handleSearchByINS = function* (ins) {
  yield put(setDmpSearchPanelOpened(searchPanels.ACCESS_BY_INS_PANEL));

  yield put(push(`/home/${searchPanels.ACCESS_BY_INS_PANEL}`));

  let regexp;
  let searchedIns;
  if (ins.insC) {
    searchedIns = ins.insC;
    regexp = /^\d{22}$/;
  } else if (ins.insNir) {
    searchedIns = ins.insNir;
    regexp = /^\d{15}[a-zA-Z]?$/;
  }
  yield put(setFindPatientsIns(searchedIns));
  const valid = searchedIns && regexp && regexp.test(searchedIns);

  if (!valid) {
    const details = [createErrorDetails('Erreur détaillée', { Ins: ins })];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: ins.insC ? softwareErrors.INSC_INVALID_FORMAT : softwareErrors.INS_NIR_INVALID_FORMAT,
    }, details);

    yield put(setModalError(modalError));
  } else {
    yield put(clearSection(apiSections.INSC_TO_NIR));
    yield put(clearSection(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION));
    if (ins.insC) {
      yield put(getAction(
        commands.getInsNirFromInsC,
        apiSections.INSC_TO_NIR,
        { s_insC: ins.insC },
      ));
    } else if (ins.insNir && isInsValid(ins.insNir)) {
      yield put(getDirectAuthenticationDMPStatus(ins.insNir));
    }

    yield put(selectINS(ins.insNir));
    const directAuthResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
    ]);

    if (
      directAuthResult
        && directAuthResult.type === dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION)
    ) {
      const {
        ExistingTestAnswer: {
          i_dmpStatus,
          AdminData: {
            Ins: {
              s_ins,
              s_insType,
            },
          },
        },
      } = directAuthResult.data;
      if (i_dmpStatus === dmpStatuses.DMPExist) {
        const { accessRights } = yield select(getAccessRightsProps);
        const dmpLandingPage = yield select(getDmpLandingPage);
        yield put(push(getDocumentRedirectUrl(accessRights, s_ins + s_insType, dmpLandingPage)));
      }
    }
  }
};

const handleSearchPatient = function* (search) {
  yield put(clearSection(apiSections.FIND_PATIENTS_SECTION));
  yield put(setDmpSearchPanelOpened(searchPanels.ACCESS_BY_SEARCH_PANEL));
  const form = mergeWith({}, findPatientsDefaultValues, search, (a, b) => (b === null || b === undefined ? a : b));
  const isFormValid = findPatientsParamsSchema.isValidSync(form, { abortEarly: false });
  yield put(setFindPatientsParams(form));

  if (isFormValid) {
    yield put(getAction(
      commands.findPatient,
      apiSections.FIND_PATIENTS_SECTION,
      formatFindPatientsParams(
        form.name,
        form.givenName,
        form.city,
        form.postalCode,
        form.birthday,
        form.sex,
        form.approxName,
        form.approxCity,
      ),
    ));
    const { data: { Patients: patients } } = yield take([
      'DMPC_COMMAND_FINDPATIENTS_SUCCESS',
      'DMPC_COMMAND_FINDPATIENTS_FAILURE',
    ]);
      // If there's only one result we redirect to the patient page
    if (patients && patients.length === 1) {
      const patient = patients[0];
      const { Ins: { s_ins: ins, s_insType: insType } } = patient;

      // we run TD0.2 transaction
      yield put(selectINS(ins + insType));
      yield put(getDirectAuthenticationDMPStatus(ins + insType));


      const directAuthResult = yield take([
        dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      ]);

      if (
        directAuthResult
        && directAuthResult.type === dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION)
      ) {
        // we redirect to the patient page
        const { accessRights } = yield select(getAccessRightsProps);
        const dmpLandingPage = yield select(getDmpLandingPage);
        yield put(push(getDocumentRedirectUrl(accessRights, ins + insType, dmpLandingPage)));
      } else {
        yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
      }
    } else {
      yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
    }
  } else {
    yield put(push(`/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
  }
};

const handleShowVSMorRH = function* (payload) {
  const dmpConfiguration = yield select(getDmpconnectPersistedConfiguration);
  const hrVsmAutoOpen = yield getConfigurationValue('hrVsmAutoOpen', dmpConfiguration);
  const { accessRights } = yield select(getAccessRightsProps);

  if (
    isTransactionAllowed(accessRights, transactions.FIND_DOCUMENTS_META)
    && hrVsmAutoOpen
    && (
      payload.location.pathname.endsWith('documents')
    && payload.location.hash === dmpconnectHashes.REDIRECT_TO_LAST_VSM_OR)
  ) {
    const { command: { s_ins } } = yield take(dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION));

    const pathname = yield select(getCurrentPathname);
    // meanwhile the user may have move back to the dashboard
    // TODO handle command aborting when unmounting component
    const lastNewDocs = yield select(getAllDocsFromCache, s_ins);
    if (pathname.endsWith('documents')) {
      const vsm = yield getLastVSM(lastNewDocs);
      const hr = yield getLastHR(lastNewDocs);
      yield put(replace(`/dmp/${encodeIns(s_ins)}/documents`));
      if (vsm) {
        const { s_uniqueId, s_uuid } = vsm;
        yield put(push(`/dmp/${encodeIns(s_ins)}/document/${encodeIns(getUniqueUUid(s_uniqueId, s_uuid))}`));
      } else if (hr) {
        const { s_uniqueId, s_uuid } = hr;
        yield put(push(`/dmp/${encodeIns(s_ins)}/document/${encodeIns(getUniqueUUid(s_uniqueId, s_uuid))}`));
      }
    }
  }
};

const handleSearchFromUrl = function* (ins, search, pathname) {
  if (ins) {
    yield call(handleSearchByINS, ins);
  } else if (search) {
    yield call(handleSearchPatient, { ...search, approxCity: !!search.approxCity, approxName: !!search.approxName });
  }
};

const handleEsSettings = function* (settings) {
  const {
    id,
    locationName,
    activitySector,
    practiceSetting,
  } = settings;

  // const activitySectors = yield select(getInteropCodesFromState, 'activitySectors');
  // const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');

  const validator = yup.object({
    id: yup.string().required('L\'identifiant est manquant'),
    locationName: yup.string().required('Le nom de l\'établissement est manquant'),
    activitySector: yup.string()
      // .oneOf(
      //   activitySectors.map(ps => ps.code),
      //   ({ values }) => `Le secteur d'activité doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le secteur d\'activité est manquant'),
    practiceSetting: yup.string()
      // .oneOf(
      //   practiceSettings.map(ps => ps.code),
      //   ({ values }) => `Le cadre d'exercice doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le cadre d\'exercice est manquant'),
  });

  try {
    validator.validateSync(settings, { abortEarly: false });

    yield put(setESConfiguration('es_id', id));
    yield put(setESConfiguration('practiceLocationName', locationName));
    yield put(setESConfiguration('activitySector', activitySector));
    yield put(setESConfiguration('practiceSetting', practiceSetting));

    toast.dismiss('es-config');
    toast.success('Configuration de l\'établissement mise à jour', {
      autoClose: 2000,
      toastId: 'es-config',
    });
  } catch (validationError) {
    const details = [
      createErrorDetails('Errors', validationError.inner.map(error => ({
        field: error.path,
        value: error.value,
        error: error.message,
      }))),
      createErrorDetails('provided', settings),
    ];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: softwareErrors.INVALID_ES_SETTINGS,
    }, details);

    yield put(setModalError(modalError));
  }
};

const handleSettings = function* (settings) {
  if (settings.dpExportUrl) {
    try {
      const url = settings.dpExportUrl;
      if (isUrlValid(url)) {
        yield put(setPersistedAppConfiguration('dpExportUrl', url));
        toast.dismiss('dpExportUrl');
        toast.success('Adresse d\'export des dispensations mise à jour', {
          autoClose: 2000,
          toastId: 'dpExportUrl',
        });
      } else {
        throw new Error('Url is invalid');
      }
    } catch (e) {
      toast.error((
        // eslint-disable-next-line react/jsx-filename-extension
        <div>
          <div className="font-weight-bold">Erreur</div>
          <span>L&apos;adresse d&apos;export des dispensations fournie n&apos;est pas valide</span>
        </div>
      ), {
        autoClose: false,
        toastId: 'dpExportUrl',
      });
    }
  }

  if (settings.hpSpeciality) yield put(setESConfiguration('hpSpeciality', settings.hpSpeciality));

  if (settings.airAuthenticationMode) {
    const mode = Object.values(availableAirStrongAuthModes).find(authMode => authMode.value === Number(settings.airAuthenticationMode));
    if (!mode) {
      const details = [createErrorDetails('Erreur détaillée', settings)];
      const modalError = createModalError({
        i_apiErrorType: errorTypes.SoftwareErrors,
        i_apiErrorCode: softwareErrors.AIR_AUTH_MODE_INVALID,
      }, details);

      yield put(setModalError(modalError));
    } else {
      yield put(setESConfiguration('hpAuthenticationMode', getEsUserAuthenticationMode(settings.airAuthenticationMode)));
      yield put(setESConfiguration('hpAuthenticationContext', getEsUserAuthenticationContext(settings.airAuthenticationMode)));
    }
  } else {
    if (settings.hpAuthenticationMode) {
      yield put(setESConfiguration('hpAuthenticationMode', settings.hpAuthenticationMode));
    }
    if (settings.hpAuthenticationMode) {
      yield put(setESConfiguration('hpAuthenticationContext', settings.hpAuthenticationContext));
    }
  }
};

// const isInitialized = state => state.dmpconnectInit.applicationInitialized;
const handleMail = function* (mail, pathname) {
  if (mail) {
    yield put(resetMssEmailContent(mail.attachments.length > 0));

    yield put(setMssEmailContent({
      recipients: mail.recipients,
      cc: mail.cc,
      bcc: mail.bcc,
      title: mail.title,
      messageContent: mail.messageContent,
      isHtml: mail.isHtml,
      senderWording: mail.senderWording,
      replyTo: mail.replyTo,
      messageId: mail.messageId,
      inReplyToMessageIds: mail.inReplyToMessageIds,
      references: mail.references,
      attachments: mail.attachments.map(att => ({
        patientIns: att.s_patientIns,
        fileContentInBase64: att.s_fileContentInBase64,
        documentTitle: att.s_documentTitle,
        documentDescription: att.s_documentDescription,
        documentCategory: att.s_documentCategory,
        documentFormat: att.i_documentFormat,
        healthcareSetting: att.s_healthcareSetting,
        versionNumber: att.s_versionNumber,
        setIdRoot: att.s_setIdRoot,
        setIdExtension: att.s_setIdExtension,
        uniqueId: att.s_uniqueId,
        replacedDocumentUniqueId: att.s_replacedDocumentUniqueId,
        performer: att.performer,
        treatingPhysician: att.treatingPhysician,
        additionalAuthors: att.additionalAuthors,
        informants: att.informants,
        intendedRecipients: att.intendedRecipients,
        EventCodes: att.EventCodes,
      })),
    }));
    yield put(setShowMssPopup(true));
    let redirect = pathname;
    if (pathname === '/') {
      redirect = '/home/';
    }
    yield put(push(redirect));
  }
};

const checkSaasToken = function* (providedToken, saasTokenConfig) {
  const { saasToken, validatedSaasTokens = [] } = saasTokenConfig;
  let validated = false;

  if (saasToken && saasToken !== '') {
    // check if token has already been validated
    if (validatedSaasTokens) {
      const validatedToken = validatedSaasTokens.some(t => t.token === saasToken);
      if (validatedToken) {
        validated = true;
      }
    }

    if (validated === false) {
      // validate the provided token agains configured one
      if (providedToken === saasToken) {
        const newValidatedTokens = [
          ...validatedSaasTokens,
          {
            token: providedToken,
            validatedAt: moment().unix(),
          },
        ];
        // set token as validated
        yield put(setPersistedAppConfiguration('validatedSaasTokens', newValidatedTokens));
        validated = true;
      }
    }
  } else {
    validated = true;
  }
  yield put(setSaasTokenValidated(validated));
  return validated;
};

export const handleLocationChange = function* () {
  while (true) {
    const { payload } = yield take(LOCATION_CHANGE);
    const { location: { pathname, hash, search } } = payload;
    if (pathname === '/login-token') {
      yield put(logoutSuccess());
    }

    const cleanedHash = hash.slice(1);
    const cleanedSearch = search.slice(1);
    const mergedSearchAndHash = mergeSearchAndHash(cleanedSearch, cleanedHash);
    const parameters = parseQueryString(mergedSearchAndHash);
    const { params, user, person } = parameters || {};
    const saasTokenConfig = yield select(getSaasTokenConfig);
    const esConfig = yield select(getDmpconnectESConfiguration);
    const esLoginType = getConfigurationValue('loginType', esConfig);
    const connectorConfig = yield select(getDmpconnectPersistedConnectorConfig);
    const onlyGeneratedConnectorJWT = getConfigurationValue('onlyGeneratedConnectorJWT', connectorConfig);
    let decodedParams;
    let parsedParams;
    const loggedIn = yield call(checkIfUserIsLoggedIn, payload);

    try {
      yield put(clearModalError());

      let LoginCheck;
      let Ins;
      let Search;
      let Settings;
      let Mail;
      let tseId;
      let saasToken;
      let jwtSecret;
      let connectorJwt;
      let Es;
      let apiLoginCheck;
      let deactivateDashboard;

      if (params) {
        // base64 decode
        decodedParams = b64DecodeUnicode(params);
        // parse json
        parsedParams = JSON.parse(decodedParams);

        ({
          LoginCheck, Ins, Search, Settings, Mail, tseId, saasToken, jwt_secret: jwtSecret, Es, connectorJwt,
          apiLoginCheck,
          deactivateDashboard,
        } = parsedParams || {});
      }

      if (deactivateDashboard !== undefined) {
        yield put(setPersistedAppConfiguration('noDashboard', !!deactivateDashboard));
      }

      const saasTokenValidated = yield call(checkSaasToken, saasToken, saasTokenConfig);
      if (saasTokenValidated) {
        if (Es) {
          yield call(handleEsSettings, Es);
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        }

        if (connectorJwt) {
          yield put(setPersistedConnectorConfiguration('connectorJWT', connectorJwt));
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        } else if (jwtSecret && !onlyGeneratedConnectorJWT) {
          yield call(generateAndSaveToken, jwtSecret);
          if (!Ins && !Search) {
            yield put(clearLoginReferer());
          }
        }

        if (Settings) {
          yield call(handleSettings, Settings);
        }

        if (apiLoginCheck) {
          yield call(handleApiLoginCheck, apiLoginCheck);
        } else if (LoginCheck) {
          yield call(handleLoginCheck, LoginCheck);
        }

        if (tseId && pathname === '/') {
          yield put(setTseConfiguration('whoami', tseId));
          yield put(setTseConfiguration('active', true));
          toast.success('Identifiant utilisateur TSE mis à jour', {
            autoClose: 2000,
            toastId: 'whoami',
          });
          if (Ins || Search) {
            // envoyer le reste de params pour prendre en compte les recherches
            yield put(replace(`/#params=${base64.encode(JSON.stringify({ Ins, Search }))}`));
          } else {
            yield put(replace('/'));
          }
        }

        if (loggedIn) {
          yield call(handleSearchFromUrl, Ins, Search, pathname);
          yield call(handleMail, Mail, pathname);
          yield call(handleShowVSMorRH, payload);
        } else if (esLoginType === esLoginTypes.API && user && person) {
          yield put(clearLoginReferer());
          yield put(setPersistedAppConfiguration('apiLoginValues', { psInternalId: user, patientInternalId: person }));
        }

        // else if (esLoginType === esLoginTypes.API && internal_ids) {
        //   yield put(clearLoginReferer());
        //   // base64 decode
        //   decodedParams = b64DecodeUnicode(internal_ids);
        //   // parse json
        //   const { psInternalId, patientInternalId } = JSON.parse(decodedParams);
        //   yield put(setPersistedAppConfiguration('apiLoginValues', { psInternalId, patientInternalId }));
        // }
      }
    } catch (e) {
      console.log('error params', e);
      const error = createError(errorTypes.SoftwareErrors, softwareErrors.PARAMS_INVALID_JSON);
      const details = [
        createErrorDetails('provided', {
          base64: parameters,
          decoded: decodedParams,
        }),
      ];
      const modalError = createModalError(error, details);
      yield put(setModalError(modalError));
    }
    yield put(setUrlProcessed(true));
  }
};
