import mergeWith from 'lodash.mergewith';
import { dmpconnectFiltersActionConstants } from '../constants';

const initialState = {};

const setUserFilter = (state, psId, filterKey, filterValue) => {
  const {
    [psId]: {
      [filterKey]: filter,
      ...userFilters
    } = {},
    ...filters
  } = state;

  return {
    [psId]: {
      [filterKey]: filterValue,
      ...userFilters,
    },
    ...filters,
  };
};

export function dmpconnectFilters(state = initialState, action) {
  switch (action.type) {
    case dmpconnectFiltersActionConstants.DMPC_SET_USER_FILTER:
      return setUserFilter(state, action.psId, action.filterKey, action.filterValue);
    case dmpconnectFiltersActionConstants.DMPC_SET_CURRENT_FILTER:
      return mergeWith(
        {}, state, { [action.filterKey]: { ...action.filterValue } },
        (a, b) => (Array.isArray(b) ? b : undefined),
      );
    default:
      return state;
  }
}
