import * as yup from 'yup';
import { insTypes } from 'dmpconnectjsapp-base/constants';
import { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import { remoteDocumentFormats } from '../../constants/remoteConstants';
import { documentFormats, identifierTypes } from '../../constants';
import {
  informantAddressStreetNameType,
  informantAddressType,
  informantRelationType,
  informantTelecomType,
  informantType,
} from '../../constants/informants';
import '../../utils/formUtils';
import { remoteActions, remoteResponseChannels } from './index';
import { dmpSexes, insOidToType } from '../../constants/dmpConstants';

const command = {
  '@id': yup.string().required(),
  '@action': yup.string().oneOf(Object.values(remoteActions)).required(),
  '@confidentialityLevel': yup.string().oneOf(['true', 'false'], 'La valeur doit être true ou false'),
  '@responseChannel': yup.string().oneOf(Object.values(remoteResponseChannels)),
  '@responseChannelUrl': yup.string().url('L\'URL n\'est pas valide'),
};

const insiIdentity = {
  Ins: yup.object({
    startDate: yup.object({
      value: yup.string().date().nullable(),
    }).nullable(),
    endDate: yup.object({
      value: yup.string().date().nullable(),
    }).nullable(),
    value: yup.object({
      value: yup.string().length(13, 'Le numéro INS doit contenir 13 caractères.').required('Le numéro INS est manquant'),
    }),
    key: yup.object({
      value: yup.string().length(2, 'La clé du numéro INS doit contenir 2 caractères.').required('La clé du numéro INS est manquante'),
    }),
    oid: yup.object({
      value: yup.string().oneOf(
        Object.keys(insOidToType),
        ({ values }) => `Le type d'INS doit être une des valeurs suivantes : ${values}`,
      ).required('Le type d\'INS est manquant'),
    }),
  }, 'La structure Ins est manquante.'),
  lastInsiCallDate: yup.object({
    value: yup.string().date().nullable(),
  }).nullable(),
  birthName: yup.object({
    value: yup.string().required('Le nom de naissance est manquant'),
  }, 'Le nom de naissance est manquant'),
  given: yup.object({
    value: yup.string().nullable(),
  }).nullable(),
  birthGiven: yup.object({
    value: yup.string().required('La liste des prénoms est manquante'),
  }, 'La liste des prénoms est manquante'),
  sex: yup.object({
    value: yup.string().oneOf(
      Object.values(dmpSexes).map(sex => String(sex)),
      ({ values }) => `Le sexe doit être une des valeurs suivantes : ${values}`,
    ).required('le sexe est manquant'),
  }, 'le sexe est manquant'),
  birthDate: yup.object({
    value: yup.string().date().required('La date de naissance est manquante'),
  }, 'La date de naissance est manquante'),
  birthPlace: yup.object({
    value: yup.string().length(5, 'Le COG INSEE de naissance doit contenir 5 caractères').nullable(),
  }).nullable(),
};

const patientIns = {
  ins: yup.object({
    '@root': yup.string().oneOf(
      Object.values(insTypes),
      ({ values }) => `Le type de l'ins doit être une des valeurs suivantes : ${values}`,
    ).required('Le type de l\'ins est manquant'),
    '@extension': yup.string()
      .min(15, 'l\'argument extension doit être un suite de 15 chiffres')
      .max(15, 'l\'argument extension doit être un suite de 15 chiffres')
      .required('L\'ins est manquant'),
  }).notRequired().default(undefined),
  Identity: yup.object().shape(insiIdentity).notRequired().default(undefined),
  exclusive: yup.bool().test(
    'exclusive',
    'Les balises ins et Identity sont exclusives, elles ne peuvent être passées en même temps',
    function () {
      return !(this.parent.ins && this.parent.Identity);
    },
  ),
  missing: yup.bool().test(
    'missing',
    'Au moins une des deux balises, ins ou Identity, doit être présente',
    function () {
      return this.parent.ins || this.parent.Identity;
    },
  ),
};

const findDmpFilters = {
  name: yup.object({
    family: yup.object({
      value: yup.string().min(2, 'Le nom de famille doit contenir au moins 2 lettres.').nullable(),
    }).nullable(),
    given: yup.object({
      value: yup.string().nullable(),
    }).nullable(),
    '@approximate': yup.string().oneOf(['true', 'false'], 'La valeur doit être true ou false').nullable(),
  }).nullable(),
  gender: yup.object({
    value: yup.string().oneOf(['M', 'F', 'U', 'm', 'f', 'u'], 'La valeur doit être M (homme), F (femme) ou U (Inconnu)').nullable(),
  }).nullable(),
  birthday: yup.object({
    value: yup.string().date().nullable(),
  }).nullable(),
  address: yup.object({
    postalCode: yup.object({
      value: yup.string().nullable(),
    }).nullable(),
    city: yup.object({
      value: yup.string().nullable(),
    }).nullable(),
    '@approximate': yup.string().oneOf(['true', 'false'], 'La valeur doit être true ou false').nullable(),
  }).nullable(),
};

const telecomsValidator = yup.object().shape({
  telecom: yup
    .array().of(yup.object().shape({
      type: yup.object({
        value: yup.number()
          .oneOf(
            Object.values(informantTelecomType),
            'Le type d\'adresse est invalide',
          )
          .required('Le type d\'adresse est manquant.'),
      }, 'Le type d\'adresse est manquant'),
      usage: yup.object({
        value: yup.string(),
      }),
      value: yup.object({
        value: yup.string().required('La valeur est manquante'),
      }, 'La valeur est manquante'),
    }))
    .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
}).notRequired().default(undefined);

const adressesValidator = yup.object().shape({
  address: yup
    .array().of(yup.object().shape({
      type: yup.object({
        value: yup.number()
          .oneOf(
            Object.values(informantAddressType),
            'Le type d\'adresse est invalide',
          )
          .required('Le type d\'adresse est manquant.'),
      }, 'Le type d\'adresse est manquant'),
      country: yup.object({
        value: yup.string().required('Le pays est manquant'),
      }, 'Le pays est manquant'),
      city: yup.object({
        value: yup.string().required('La ville est manquante'),
      }, 'La ville est manquante'),
      postalCode: yup.object({
        value: yup.string().required('Le code postal est manquant'),
      }, 'Le code postal est manquant'),
      houseNumber: yup.object({
        value: yup.string(),
      }),
      houseNumberNumeric: yup.object({
        value: yup.string(),
      }),
      streetNameType: yup.object({
        value: yup.number()
          .oneOf(
            Object.values(informantAddressStreetNameType),
            'Le type de voie est invalide',
          )
          .required('Le type de voie est manquant.'),
      }, 'Le type de voie est manquant'),
      streetName: yup.object({
        value: yup.string().required('Le nom de la voie est manquant'),
      }, 'Le nom de la voie est manquant'),
      additionalLocator: yup.object({
        value: yup.string(),
      }),
      unitId: yup.object({
        value: yup.string(),
      }),
      postBox: yup.object({
        value: yup.string(),
      }),
      precInct: yup.object({
        value: yup.string(),
      }),
    }))
    .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
}).notRequired().default(undefined);

const hpStructureValidator = (professions, specialties, isIntendedRecipient = false) => {
  let profession = yup.string()
    .oneOf(
      professions.map(p => p.code),
      ({ values }) => `La profession doit être une des valeurs suivantes : ${values}`,
    );

  let professionOid = yup.string()
    .oneOf(
      ['1.2.250.1.71.1.2.7', '1.2.250.1.71.1.2.8'],
      'L\'identifiant de profession est invalide',
    );

  let specialty = yup.string()
    .oneOf(
      specialties.map(p => p.code),
      ({ values }) => `La spécialité doit être une des valeurs suivantes : ${values}`,
    );

  if (!isIntendedRecipient) {
    profession = profession.required('La profession est manquante.');
    professionOid = professionOid.required('L\'identifiant de profession est manquant.');
    specialty = specialty.required('La spécialité est manquante.');
  }

  return ({
    name: yup.object({
      value: yup.string().required('Le nom du PS est manquant.'),
    }, 'Le nom du PS est manquant.'),
    given: yup.object({
      value: yup.string().required('Le prénom du PS est manquant.'),
    }, 'Le prénom du PS est manquant.'),
    profession: yup.object({
      value: profession,
    }).nullable(),
    professionOid: yup.object({
      value: professionOid,
    }).nullable(),
    specialty: yup.object({
      value: specialty,
    }).nullable(),
    internalId: yup.object({
      value: yup.string().required('L\'identifiant du PS est manquant.'),
    }, 'L\'identifiant du PS est manquant.'),
    internalIdType: yup.object({
      value: yup.string()
        .oneOf(Object.values(identifierTypes), 'Le type d\'identifiant est invalide'),
    }).nullable(),
    authenticationMode: yup.object({
      value: yup.string(),
    }).nullable(),
    adresses: adressesValidator,
    telecoms: telecomsValidator,
  });
};

const eventCodeValidator = {
  code: yup.object({
    value: yup.string().required('Le code est manquant'),
  }, 'Le code est manquant'),
  classification: yup.object({
    value: yup.string().required('La classification est manquante'),
  }, 'La classification est manquante'),
  description: yup.object({
    value: yup.string().required('La description est manquante'),
  }, 'La description est manquante'),
};

const informantValidator = {
  name: yup.object({
    value: yup.string().required('Le nom est manquant'),
  }, 'Le nom est manquant'),
  given: yup.object({
    value: yup.string().required('Le nom est manquant'),
  }, 'Le nom est manquant'),
  type: yup.object({
    value: yup.number()
      .oneOf(
        Object.values(informantType),
        'Le type est invalide',
      )
      .required('Le type est manquant.'),
  }, 'Le type est manquant'),
  relationType: yup.object({
    value: yup.number()
      .oneOf(
        Object.values(informantRelationType),
        'Le type de relation est invalide',
      ),
  }).nullable(),
  adresses: adressesValidator,
  telecoms: telecomsValidator,
};

const submitDocValidator = (categories, healthcareSettings, professions, specialties) => ({
  content: yup.object({
    value: yup.string().required('Le contenu du document est manquant.'),
  }, 'Le contenu du document est manquant.'),
  format: yup.object({
    value: yup.string()
      .oneOf(
        Object.keys(remoteDocumentFormats),
        ({ values }) => `Le format du document doit être un des formats suivants : ${values}`,
      )
      .required('Le format du document est manquant.'),
  }, 'Le format du document est manquant.'),
  title: yup.object({
    value: yup.string().required('Le titre du document est manquant.'),
  }, 'Le titre du document est manquant.'),
  typeCode: yup.object({
    value: yup.string()
      .oneOf(
        categories.map(c => c.code),
        ({ values }) => `La catégorie du document doit être une des catégories suivantes : ${values}`,
      )
      .required('La catégorie du document est manquante.'),
  }, 'La catégorie du document est manquante.'),
  creationDate: yup.object({
    value: yup.string().date().pastDate(),
  }),
  serviceStartDate: yup.object({
    value: yup.string().date().pastDate(),
  }),
  serviceStopDate: yup.object({
    value: yup.string().date().pastDate(),
  }),
  role: yup.object({
    value: yup.string(),
  }).nullable(),
  practice: yup.object({
    value: yup.string()
      .oneOf(
        healthcareSettings.map(c => c.code),
        ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
      )
      .required('Le cadre de soin est manquant.'),
  }, 'Le cadre de soin est manquant.'),
  performer: yup.object()
    .shape({ ...hpStructureValidator(professions, specialties) })
    .notRequired()
    .default(undefined),
  treatingPhysician: yup.object()
    .shape({ ...hpStructureValidator(professions, specialties) })
    .notRequired()
    .default(undefined),
  additionalAuthors: yup.object().shape({
    additionalAuthor: yup
      .array().of(yup.object().shape({ ...hpStructureValidator(professions, specialties) }))
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  informants: yup.object().shape({
    informant: yup
      .array().of(yup.object().shape(informantValidator))
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  intendedRecipients: yup.object().shape({
    intendedRecipient: yup
      .array().of(yup.object().shape({ ...hpStructureValidator(professions, specialties, true) }))
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  EventCodes: yup.object().shape({
    EventCode: yup
      .array().of(yup.object().shape(eventCodeValidator))
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  retrieveDocumentUuid: yup.object({
    value: yup.number().oneOf([1, 0]),
  }),
  replacedDocumentUniqueId: yup.object({
    value: yup.string(),
  }),
  versionNumber: yup.object({
    value: yup.string(),
  }),
  setIdRoot: yup.object({
    value: yup.string(),
  }),
  setIdExtension: yup.object({
    value: yup.string(),
  }),
});

const sendMssMessageValidator = (categories, healthcareSettings, professions, specialties, mssApiType) => ({
  patient: yup.object({
    Identity: yup.object().shape(insiIdentity).notRequired().default(undefined),
  }).notRequired().default(undefined),
  sendInBackground: yup.object().shape({
    value: yup.string(),
  }),
  senderWording: yup.object().shape({
    value: yup.string(),
  }),
  replyTo: yup.object().shape({
    value: yup.string(),
  }),
  messageId: yup.object().shape({
    value: yup.string(),
  }),
  inReplyToMessageIds: yup.object().shape({
    value: yup.string(),
  }),
  references: yup.object().shape({
    value: yup.string(),
  }),
  recipient: yup.object().shape({
    email: yup
      .array().of(
        yup.object().shape({
          value: yup.string().email('L\'adresse email n\'est pas valide').required('La liste des destinataires est manquante'),
        }, 'La liste des destinataires est manquante'),
      )
      .required('La liste des destinataires est manquante')
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }),
  cc: yup.object().shape({
    email: yup
      .array().of(
        yup.object().shape({
          value: yup.string().email('L\'adresse email n\'est pas valide'),
        }),
      )
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  bcc: yup.object().shape({
    email: yup
      .array().of(
        yup.object().shape({
          value: yup.string().email('L\'adresse email n\'est pas valide'),
        }),
      )
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  title: yup.object({ value: yup.string() }).test(
    'title',
    'Le sujet du message est obligatoire lors d\'un envoi en tache de fond',
    function (title) {
      const { sendInBackground: { value: sendInBackground } } = this.parent;
      const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
      const { value } = title;
      return !isSendingInBackground || (isSendingInBackground && value);
    },
  ),
  content: yup.object({ value: yup.string() }).test(
    'content',
    'Le contenu du message est obligatoire lors d\'un envoi en tache de fond',
    function (content) {
      const { sendInBackground: { value: sendInBackground } = {} } = this.parent;
      const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
      const { value } = content;
      return !isSendingInBackground || (isSendingInBackground && value);
    },
  ),
  returnReceiptTo: yup.object({
    value: yup.string()
      .email('L\'adresse email n\'est pas valide'),
  }).test(
    'returnReceiptTo',
    'returnReceiptTo et dispositionNotificationTo ne peuvent être définis en même temps.',
    function (returnReceiptTo) {
      const { dispositionNotificationTo: { value: dispositionNotificationTo } = {} } = this.parent;
      const { value } = returnReceiptTo;
      return value === undefined || dispositionNotificationTo === undefined;
    },
  ),
  dispositionNotificationTo: yup.object({
    value: yup.string().email('L\'adresse email n\'est pas valide'),
  }).test(
    'dispositionNotificationTo',
    'returnReceiptTo et dispositionNotificationTo ne peuvent être définis en même temps.',
    function (dispositionNotificationTo) {
      const { returnReceiptTo: { value: returnReceiptTo } = {} } = this.parent;
      const { value } = dispositionNotificationTo;
      return value === undefined || returnReceiptTo === undefined;
    },
  ),
  attachments: yup.object().shape({
    attachment: yup
      .array().of(yup.object({
        versionNumber: yup.object({
          value: yup.string(),
        }),
        setIdRoot: yup.object({
          value: yup.string(),
        }),
        setIdExtension: yup.object({
          value: yup.string(),
        }),
        replacedDocumentUniqueId: yup.object({
          value: yup.string(),
        }),
        // patientIns est requis si patient.Identity n'est pas fourni
        patientIns: yup.object({
          value: yup.string().test('missing', 'L\'ins du patient est manquant', function (value) {
            const { patient: { Identity } = {} } = this.options.context;
            return value || Identity;
          }),
        }).test('missing', 'L\'ins du patient est manquant', function (value) {
          const { patient: { Identity } = {} } = this.options.context;
          return value || Identity;
        }),
        fileContentInBase64: yup.object({
          value: yup.string().required('Le contenu du document est manquant'),
        }, 'Le contenu du document est manquant'),
        documentTitle: yup.object({
          value: yup.string().required('Le titre du document est manquant'),
        }, 'Le titre du document est manquant'),
        documentDescription: yup.object({
          value: yup.string(),
        }),
        documentCategory: yup.object({
          value: yup.string()
            .oneOf(
              categories.map(c => c.code),
              ({ values }) => `La catégorie du document doit être une des catégories suivantes : ${values}`,
            )
            .required('La catégorie du document est manquante.'),
        }, 'La catégorie du document est manquante.'),
        documentFormat: yup.object({
          value: yup.string()
            .oneOf(
              Object.values(documentFormats).map(format => String(format)),
              'Le format du document est invalide.',
            )
            .required('Le format du document est manquant.'),
        }, 'Le format du document est manquant.'),
        healthcareSetting: yup.object({
          value: yup.string()
            .oneOf(
              healthcareSettings.map(c => c.code),
              ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
            )
            .required('Le cadre de soin est manquant.'),
        }, 'Le cadre de soin est manquant.').required('Le cadre de soin est manquant.'),
        performer: yup.object()
          .shape({ ...hpStructureValidator(professions, specialties) })
          .notRequired()
          .default(undefined),
        treatingPhysician: yup.object()
          .shape({ ...hpStructureValidator(professions, specialties) })
          .notRequired()
          .default(undefined),
        additionalAuthors: yup.object().shape({
          additionalAuthor: yup
            .array().of(yup.object().shape({ ...hpStructureValidator(professions, specialties) }))
            .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
        }).notRequired().default(undefined),
        informants: yup.object().shape({
          informant: yup
            .array().of(yup.object().shape(informantValidator))
            .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
        }).notRequired().default(undefined),
        intendedRecipients: yup.object().shape({
          intendedRecipient: yup
            .array().of(yup.object().shape({ ...hpStructureValidator(professions, specialties, true) }))
            .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
        }).notRequired().default(undefined),
        EventCodes: yup.object().shape({
          EventCode: yup
            .array().of(yup.object().shape(eventCodeValidator))
            .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
        }).notRequired().default(undefined),
      }))
      .required()
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
  rawAttachments: yup.object().shape({
    rawAttachment: yup
      .array().of(yup.object({
        documentTitle: yup.object({
          value: yup.string().required('Le title du document est manquant.'),
        }, 'Le titre du document est manquant'),
        fileContentInBase64: yup.object({
          value: yup.string().required('Le contenu du document est manquant.'),
        }, 'Le contenu du document est manquant'),
        contentType: yup.object(
          { value: yup.string().required('Le type mime du document est manquant.') },
          'Le type mime du document est manquant.',
        ).test(
          'contentType',
          'Le type mime du document ne peut dépasser 40 caractères.',
          (contentType) => {
            const { value } = contentType;
            return !(mssApiType === mssSubTypes.WEB && value.length > 40);
          },
        ),
      }))
      .required()
      .transform(function (value, originalValue) { return !this.isType(value) ? [originalValue] : value; }),
  }).notRequired().default(undefined),
});

const deleteDocumentValidator = healthcareSettings => ({
  patient: yup.object().shape({ ...patientIns }),
  document: yup.object({
    uniqueId: yup.object({
      value: yup.string().required('L\'identifiant unique (uniqueId) du document est manquant'),
    }, 'L\'identifiant unique du document est manquant'),
    uuid: yup.object({
      value: yup.string(),
    }),
    practice: yup.object({
      value: yup.string()
        .oneOf(
          healthcareSettings.map(c => c.code),
          ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
        )
        .required('Le cadre de soin est manquant.'),
    }, 'Le cadre de soin est manquant.'),
  }, 'Les informations du document à supprimer sont manquantes'),
});

const generateValidators = (categories, healthcareSettings, professions, specialties, mssApiType) => ({
  [remoteActions.logout]: yup.object().shape(command),
  [remoteActions.getCurrentDmp]: yup.object().shape(command),
  [remoteActions.getStatus]: yup.object().shape(command),
  [remoteActions.getEfficienceVersion]: yup.object().shape(command),
  [remoteActions.closeDmpSession]: yup.object().shape(command),
  [remoteActions.testDmpExistence]: yup.object().shape({
    ...command,
    patient: yup.object().shape({ ...patientIns }),
  }),
  [remoteActions.findDmp]: yup.object().shape({
    ...command,
    filters: yup.object().shape(findDmpFilters),
  }),
  [remoteActions.getCertifiedIdentity]: yup.object().shape({
    ...command,
    patient: yup.object({
      nir: yup.object({
        '@root': yup.string().oneOf(
          Object.values(insTypes),
          ({ values }) => `Le type du nir doit être une des valeurs suivantes : ${values}`,
        ).required('Le type du nir est manquant'),
        '@extension': yup.string()
          .min(15, 'l\'argument extension doit être un suite de 15 chiffres')
          .max(15, 'l\'argument extension doit être un suite de 15 chiffres')
          .required('Le nir est manquant'),
      }, 'Le nir est manquant'),
      birthDate: yup.object({
        value: yup.string().date().required('La date de naissance est manquante'),
      }, 'La date de naissance est manquante'),
      birthRank: yup.object({
        value: yup.number().required('Le rang de naissance est manquant'),
      }, 'Le rang de naissance est manquant'),
    }),
  }),
  [remoteActions.submitDocument]: yup.object().shape({
    ...command,
    patient: yup.object().shape({ ...patientIns }),
    sendInBackground: yup.object().shape({
      value: yup.string(),
    }),
    document: yup.object().shape({
      ...submitDocValidator(categories, healthcareSettings, professions, specialties),
    }),
  }),
  [remoteActions.sendMssMessage]: yup.object().shape({
    ...command,
    ...sendMssMessageValidator(categories, healthcareSettings, professions, specialties, mssApiType),
  }),
  [remoteActions.deleteDocument]: yup.object().shape({
    ...command,
    ...deleteDocumentValidator(healthcareSettings),
  }),
});

export const validateCommand = (request, categories, healthcareSettings, professions, specialties, mssApiType) => {
  yup.object().shape(command).validateSync(request, { abortEarly: false });
  const { '@action': action } = request;
  const validators = generateValidators(categories, healthcareSettings, professions, specialties, mssApiType);
  const validator = validators[action];
  if (validator) {
    validator.validateSync(request, { abortEarly: false, context: request });
  }
};
