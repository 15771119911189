import {
  curlErrorDefaultDescription,
  dmpConnectJSErrorDefaultDescription, dmpConnectMSSErrorDefaultDescription,
  dmpConnectorErrors, dmpConnectTLSiErrorDefaultDescription,
  dmpErrorDefaultDescription, dpErrorDefaultDescription,
  errorTypes, openIDErrorDefaultDescription, remoteErrorDefaultDescription,
  vitaleErrorDefaultDescription,
  xdsDmpErrorDefaultDescription,
} from './errorConfiguration';
import 'moment/locale/fr';


export const getErrorDefaultDescription = (error) => {
  if (error.i_apiErrorType === errorTypes.VitaleErrors) {
    return vitaleErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.CurlErrors) {
    return curlErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.DmpConnectJsSoftwareErrors) {
    return dmpConnectJSErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.DMPErrors) {
    if (error.i_apiErrorCode >= 27) {
      return {
        ...xdsDmpErrorDefaultDescription,
        message: error.s_apiErrorDmpErrorDetails,
      };
    }
    return dmpErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.RemoteErrors) {
    return remoteErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.MssErrors) {
    return dmpConnectMSSErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.TLSiErrors) {
    return {
      ...dmpConnectTLSiErrorDefaultDescription,
      title: error.s_apiErrorTlsiErrorSoapReason,
      message: error.s_apiErrorTlsiErrorDetailError,
    };
  }
  if (error.i_apiErrorType === errorTypes.DPErrors) {
    return dpErrorDefaultDescription;
  }
  if (error.i_apiErrorType === errorTypes.openIDErrors) {
    return openIDErrorDefaultDescription;
  }
  return dmpConnectJSErrorDefaultDescription;
};

export const getErrorDescription = (error) => {
  let description = getErrorDefaultDescription(error);
  if (dmpConnectorErrors[error.i_apiErrorType]) {
    if (dmpConnectorErrors[error.i_apiErrorType][error.i_apiErrorCode]) {
      description = dmpConnectorErrors[error.i_apiErrorType][error.i_apiErrorCode];
    }
  }
  return description;
};

export const getErrorTitle = (error) => {
  const errorDescription = getErrorDescription(error);
  if (errorDescription) {
    return errorDescription.title;
  }
  return "Une erreur s'est produite";
};

export const getErrorMessage = (error) => {
  const errorDescription = getErrorDescription(error);
  if (errorDescription && errorDescription.message !== undefined) {
    return errorDescription.message;
  }
  return error.s_apiErrorDescription;
};

export const createError = (errorType, errorCode) => ({
  i_apiErrorType: errorType,
  i_apiErrorCode: errorCode,
});

export const createModalError = (error, details = []) => ({ error, details });

export const createErrorDetails = (name, content) => ({ name, content });
