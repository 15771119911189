import {
  remoteDmpStatuses,
  remoteUserAuthorizationStatuses,
} from '../../constants/remoteConstants';

export const extractTestDmpExistenceParams = (patient) => {
  const {
    patient: {
      ins: {
        '@root': root,
        '@extension': extension,
      },
    },
  } = patient;

  return {
    extension,
    root,
  };
};

export const generateTestDmpExistenceResponse = (requestId, existingTestAnswer) => {
  const {
    i_dmpStatus: dmpStatus,
    i_userAuthorization: authorization,
  } = existingTestAnswer;

  return {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    dmp: {
      '@status': remoteDmpStatuses[dmpStatus],
      '@authorization': remoteUserAuthorizationStatuses[authorization],
    },
  };
};
