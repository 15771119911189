import {objectToQueryParams, generateId, getObjectKeyFromPath} from '../dmpconnect/utils/dataUtils';
import JwksClient from "jwks-rsa";
import {JwtPayload, verify} from 'jsonwebtoken';
import getPkce from 'oauth-pkce';
import env from "../envVariables";
import {getAction, setModalError, setUserJWT} from '../dmpconnect/actions';
// @ts-ignore
import commands from 'dmpconnectjsapp-base/actions/config/commands';
// @ts-ignore
import {softwareErrors} from 'dmpconnectjsapp-base/errors';
import {createError} from '../dmpconnect/errors';
import {errorTypes} from '../dmpconnect/errors/errorConfiguration';
// @ts-ignore
import {logoutSuccess} from "dmpconnectjsapp-base/actions";
import {openIDErrors} from "../dmpconnect/errors/errorConstants";

export type OidcClientSettings = {
    // issuer: string,
    clientId: string,
    clientSecret?: string | undefined,
    signKey?: string | undefined,
    authorizeEndpoint: string,
    tokenEndpoint: string,
    jwksEndpoint: string,
    userInfoEndpoint: string,
    userInfoMethod: "GET" | "POST",
    endSessionEndpoint?: string,
    scope: string,
    responseType: string,
    deactivatePCKE?: boolean,
    acrValues?: string,
    redirectURI: string,
    logoutURI: string,
    userInfosMapper?: string,
    callUserInfos?: boolean,
    loginCheckMapper?: string,
    connectorToken?: string,
    apiType: string,
    dcParams?: string,
}
type UserInfosMapper = {
    hpName: string,
    hpGiven: string,
    hpProfession: string,
    hpProfessionOid: string,
    hpSpeciality: string,
    hpInternalId: string,
    hpAuthenticationMode: string,
}
type PscUserInfosMapper = {
    hpName: string,
    hpGiven: string,
    professionIdentifier: string,
    professionCode: string,
    specialityCode: string,
    hpInternalId: string,
}
type PKCE = {
    verifier: string,
    challenge: string,
}
type LoginCheck = {
    name?: string,
    given?: string,
    rpps?: string
}

const pscUserInfosMapper: PscUserInfosMapper = {
    hpName: "family_name",
    hpGiven: "given_name",
    professionIdentifier: "SubjectRole",
    professionCode: "codeProfession",
    specialityCode: "codeSavoirFaire",
    hpInternalId: "SubjectNameID",
};
const pscLoginCheckMapper: LoginCheck = {
    name: "family_name",
    given: "given_name",
    rpps: "SubjectNameID",
};

export default class OidcClient {
    set cpxLogin(value: boolean) {
        this._cpxLogin = value;
    }

    set loggedIn(value: boolean) {
        this._loggedIn = value;
    }

    set dispatch(value: Function | undefined) {
        this._dispatch = value;
    }

    // private _issuer: string;
    private _clientId: string;
    private _clientSecret: string | undefined;
    private _signkey: string | undefined;
    private _authorizeEndpoint: string;
    private _tokenEndpoint: string;
    private _jwksEndpoint: string;
    private _userInfoEndpoint: string;
    private _userInfoMethod: string;
    private _endSessionEndpoint: string | undefined;
    private _scope: any;
    private _responseType: string;
    private _deactivatePCKE: boolean;
    private _acrValues: string;
    private _user: any;
    private _loggedIn: boolean;
    private _redirectURI: string;
    private _logoutUri: string;
    private _expiredEventInterval: NodeJS.Timeout | undefined;
    private _callUserInfos: boolean;
    private _userInfosMapper: UserInfosMapper | undefined;
    private _loginCheckMapper: LoginCheck | undefined;
    private _dispatch: Function | undefined;
    private _cpxLogin: boolean;
    private _connectorToken: string | undefined;
    private _apiType: string;
    private _dcParams: string | undefined;

    constructor(settings: OidcClientSettings) {
        // this._issuer = settings.issuer;
        this._clientId = settings.clientId;
        this._clientSecret = settings.clientSecret;
        this._signkey = settings.signKey;
        this._authorizeEndpoint = settings.authorizeEndpoint;
        this._tokenEndpoint = settings.tokenEndpoint;
        this._jwksEndpoint = settings.jwksEndpoint;
        this._userInfoEndpoint = settings.userInfoEndpoint;
        this._userInfoMethod = settings.userInfoMethod;
        this._endSessionEndpoint = settings.endSessionEndpoint;
        this._scope = settings.scope;
        this._responseType = settings.responseType;
        this._deactivatePCKE = settings.deactivatePCKE || false;
        this._acrValues = settings.acrValues || '';
        this._redirectURI = settings.redirectURI;
        this._logoutUri = settings.logoutURI;
        this._loggedIn = false;
        this._cpxLogin = false;
        this._callUserInfos = settings.callUserInfos || false;
        if (settings.userInfosMapper) {
            this._userInfosMapper = JSON.parse(settings.userInfosMapper);
        }
        if (settings.loginCheckMapper) {
            this._loginCheckMapper = JSON.parse(settings.loginCheckMapper);
        }
        this._connectorToken = settings.connectorToken || undefined;
        this._apiType = settings.apiType;
        this._dcParams = settings.dcParams;
    }

    public setConfig(settings: OidcClientSettings) {
        this._clientId = settings.clientId;
        this._clientSecret = settings.clientSecret;
        this._signkey = settings.signKey;
        this._authorizeEndpoint = settings.authorizeEndpoint;
        this._tokenEndpoint = settings.tokenEndpoint;
        this._jwksEndpoint = settings.jwksEndpoint;
        this._userInfoEndpoint = settings.userInfoEndpoint;
        this._userInfoMethod = settings.userInfoMethod;
        this._endSessionEndpoint = settings.endSessionEndpoint;
        this._scope = settings.scope;
        this._responseType = settings.responseType;
        this._deactivatePCKE = settings.deactivatePCKE || false;
        this._acrValues = settings.acrValues || '';
        this._redirectURI = settings.redirectURI;
        this._logoutUri = settings.logoutURI;
        this._loggedIn = false;
        this._cpxLogin = false;
        this._callUserInfos = settings.callUserInfos || false;
        if (settings.userInfosMapper) {
            this._userInfosMapper = JSON.parse(settings.userInfosMapper);
        }
        if (settings.loginCheckMapper) {
            this._loginCheckMapper = JSON.parse(settings.loginCheckMapper);
        }
        this._connectorToken = settings.connectorToken || undefined;
        this._apiType = settings.apiType;
        this._dcParams = settings.dcParams;
    }

    get user(): any {
        return this._user;
    }

    private _setUser(user: any, connectorToken: string | undefined) {
        this._user = user;
        if (this._expiredEventInterval) {
            clearTimeout(this._expiredEventInterval);
        }
        this._expiredEventInterval = setTimeout(() => this.renew(connectorToken), (user.token.expires_in - 10) * 1000);
        // this._expiredEventInterval = setTimeout(() => this.renew(), (20 * 60) * 1000);
    }

    public async setUserAndRenew(user: any, connectorToken: string | undefined) {
        this._user = user;
        await this.renew(connectorToken);
    }

    get loggedIn(): boolean {
        return this._loggedIn;
    }

    private async _getSecurityValues(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const state = sessionStorage.getItem('oidc_state') || generateId(50);
            sessionStorage.setItem('oidc_state', state);
            const nonce = sessionStorage.getItem('oidc_nonce') || generateId(50);
            sessionStorage.setItem('oidc_nonce', nonce);

            const {verifier, challenge} = await new Promise<{verifier: string, challenge: string}>((resolve) => {
                getPkce(43, (error, {verifier, challenge}) => {
                    if (error) throw error;
                    resolve({verifier, challenge});
                });
            });
            const sessionPCKE = sessionStorage.getItem('oidc_pkce');
            const pkce = sessionPCKE ? JSON.parse(sessionPCKE) : {verifier, challenge};

            sessionStorage.setItem('oidc_pkce', JSON.stringify(pkce));

            resolve({state, nonce, pkce});
        });
    }

    private _exchangeCodeForToken(params: object, connectorToken: string | undefined): any {
        let parameters = params;
        const headers = {
            'Content-type': 'application/x-www-form-urlencoded',
        };
        if (connectorToken) {
            Object.assign(headers, {
                Authorization: `Bearer ${connectorToken}`
            });
        }

        if (this._apiType === 'WS') {
            parameters = {
                ...parameters,
                dcparameters64: this._dcParams,
            }
        }
        return fetch(this._tokenEndpoint, {
            method: 'post',
            headers,
            body: objectToQueryParams(parameters),
        })
            .then(response => response.json())
            .then(json => json);
    }

    private async _validateIdToken(id_token: string, nonce: string): Promise<{
        valid: boolean,
        decodedToken?: string | JwtPayload | undefined,
        error?: object | undefined,
    }> {
        const client = JwksClient({
            jwksUri: this._jwksEndpoint,
        });

        const getKey = (header: any, callback: CallableFunction) => {
            client.getSigningKey(header.kid, (err, key) => {
                let signingKey;
                if ("publicKey" in key) {
                    signingKey = key.publicKey;
                }
                if ("rsaPublicKey" in key) {
                    signingKey = key.rsaPublicKey;
                }
                callback(null, signingKey);
            })
        };

        return new Promise((resolve, reject) => {
            verify(
                id_token,
                this._signkey || getKey,
                {nonce},
                (err, decoded) => {
                    if (!err) {
                        resolve({valid: true, decodedToken: decoded});
                    } else {
                        reject(err.message);
                    }
                });
        })
    }

    private async _getUserInfos(connectorToken: string | undefined): Promise<any> {
        const token = this._user.token;
        const headers = this._userInfoMethod === "GET" ? {
            'Authorization': `${token.token_type} ${token.access_token}`,
        } : {
            'Content-type': 'application/x-www-form-urlencoded',
        };
        if (connectorToken && this._userInfoMethod === "POST") {
            Object.assign(headers, {
                Authorization: `Bearer ${connectorToken}`
            });
        }
        return fetch(
            this._userInfoEndpoint,
            {
                method: this._userInfoMethod,
                // @ts-ignore
                headers,
                body: this._userInfoMethod === "POST" ? objectToQueryParams({
                    type: token.token_type,
                    credentials: token.access_token
                }) : undefined,
            }
        ).then(response => response.json());
    }

    private mapUserInfos(userInfos: any): any {
        let mapper: UserInfosMapper | PscUserInfosMapper = pscUserInfosMapper;
        if (this._userInfosMapper) {
            mapper = this._userInfosMapper;
        }

        const mapped = Object.entries(mapper).reduce((o, [key, path]) => {
            const value = getObjectKeyFromPath(path, userInfos);
            return {...o, [key]: value};
        }, {}) as any;

        if (!this._userInfosMapper) {
            const [professionIdentifier] = mapped.professionIdentifier;
            const [profession] = professionIdentifier.split('^');
            let speciality;
            let professionOid;
            let activities = [];
            if (userInfos.SubjectRefPro && userInfos.SubjectRefPro.exercices) {
                const exercice = userInfos.SubjectRefPro.exercices.find((e: { codeProfession: any; }) => e.codeProfession === profession);
                speciality = exercice[(mapper as PscUserInfosMapper).specialityCode];
                professionOid = exercice.codeCategorieProfessionnelle;


                if (exercice.activities) {
                    activities = exercice.activities;

                    if (activities.length === 1 && profession === 21) {
                        speciality = activities[0].codeSectionPharmacien;
                    }
                }
            }
            return {
                hpName: mapped.hpName,
                hpGiven: mapped.hpGiven,
                hpProfession: profession || 'SECRETARIAT_MEDICAL',
                hpProfessionOid: professionOid === 'E' ? '1.2.250.1.71.1.2.8' : '1.2.250.1.71.1.2.7',
                hpSpeciality: speciality,
                hpInternalId: mapped.hpInternalId,
                activities,
            }
        } else {
            return mapped;
        }
    }

    private mapLoginCheck(userInfos: any): any {
        let mapper = pscLoginCheckMapper;
        if (this._loginCheckMapper) {
            mapper = this._loginCheckMapper;
        }

        return Object.entries(mapper).reduce((o, [key, path]) => {
            const value = getObjectKeyFromPath(path, userInfos);
            return {...o, [key]: value};
        }, {});
    }

    public signout(): void {

        this._loggedIn = false;
        this._user = null;
        sessionStorage.clear();
    }

    public requestSignout(): void {
        if (this._expiredEventInterval) {
            clearTimeout(this._expiredEventInterval);
        }

        if (this._endSessionEndpoint && this._user) {
            const {token: {id_token}} = this._user;
            const queryString = objectToQueryParams({
                id_token_hint: id_token,
                post_logout_redirect_uri: this._redirectURI,
                state: generateId(50)
            });
            window.location.href = `${this._endSessionEndpoint}${queryString ? `?${queryString}` : undefined}`;
        } else {
            this.signout();
        }
    }

    public async signin(): Promise<any> {
        const {state, nonce, pkce} = await this._getSecurityValues();
        if (!this._loggedIn) {
            const args = {
                client_id: this._clientId,
                redirect_uri: this._redirectURI,
                scope: this._scope,
                acr_values: this._acrValues,
                state: state,
                nonce: nonce,
                response_type: this._responseType,
            }
            if (!this._deactivatePCKE && pkce) {
                Object.assign(args, {
                    code_challenge: pkce.challenge,
                    code_challenge_method: 'S256',
                });
            }

            window.location.href = this._authorizeEndpoint + '?' + objectToQueryParams(args);
        }
    }

    public async signinCallback(loginCheckValues: LoginCheck, forceLoginCheck: boolean): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const {state, nonce, pkce} = await this._getSecurityValues();

            const url = new URL(window.location.href);
            const urlState = url.searchParams.get('state');
            if (!urlState || urlState !== state) {
                reject({
                    i_apiErrorCode: openIDErrors.unmatched_state,
                    i_apiErrorType: errorTypes.openIDErrors,
                });
            } else {
                try {
                    const code = url.searchParams.get('code');
                    const params = {
                        grant_type: 'authorization_code',
                        code,
                        redirect_uri: this._redirectURI,
                        client_id: this._clientId,
                    };

                    if (!this._deactivatePCKE && pkce) {
                        Object.assign(params, {code_verifier: pkce.verifier});
                    } else {
                        Object.assign(params, {client_secret: this._clientSecret});
                    }

                    const token = await this._exchangeCodeForToken(params, this._connectorToken);
                    const {id_token} = token;

                    if (!id_token) {
                        if (token.error) {
                            reject({
                                i_apiErrorCode: token.error,
                                i_apiErrorType: errorTypes.openIDErrors,
                                ...token,
                            })
                        } else {
                            reject(token);
                        }
                    }

                    const {valid, decodedToken, error} = await this._validateIdToken(id_token, nonce);

                    if (!valid) {
                        reject({
                            i_apiErrorCode: openIDErrors.invalid_token,
                            i_apiErrorType: errorTypes.openIDErrors,
                            ...error,
                        });
                    }

                    this._user = {
                        decoded_id_token: decodedToken,
                        token,
                    };

                    let userInfos = decodedToken;
                    if (this._callUserInfos) {
                        userInfos = await this._getUserInfos(this._connectorToken);
                    }

                    // compare values with logincheck
                    if (forceLoginCheck) {
                        const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
                        const loginCheckOK = loginCheckValues && requiredValues.every((val: string) => val in loginCheckValues);
                        if (loginCheckOK) {
                            const mappedLoginCheck = this.mapLoginCheck(userInfos);
                            const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
                            const nameOK = !requiredValues.includes('name') || loginCheckValues.name === mappedLoginCheck.name;
                            const givenOK = !requiredValues.includes('given') || loginCheckValues.given === mappedLoginCheck.given;
                            const rppsOK = !requiredValues.includes('rpps') || loginCheckValues.rpps === mappedLoginCheck.rpps;
                            if (!(nameOK && givenOK && rppsOK)) {
                                if (this._expiredEventInterval) {
                                    clearTimeout(this._expiredEventInterval);
                                }
                                reject({
                                    i_apiErrorCode: openIDErrors.login_check_failed,
                                    i_apiErrorType: errorTypes.openIDErrors,
                                });
                            }
                        } else {
                            reject({
                                i_apiErrorCode: openIDErrors.missing_login_check,
                                i_apiErrorType: errorTypes.openIDErrors,
                                provided: { ...loginCheckValues },
                                requiredValues,
                            });
                        }
                    }

                    const mappedUserInfos = this.mapUserInfos(userInfos);

                    this._setUser({...this._user, profile: mappedUserInfos}, this._connectorToken);
                    this._loggedIn = true;
                    resolve(this._user);

                } catch (e) {
                    reject({
                        i_apiErrorCode: openIDErrors.unknown,
                        i_apiErrorType: errorTypes.openIDErrors,
                        error: e,
                    });
                }
            }
        });
    }

    public async renew(connectorToken: string | undefined): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (this._loggedIn) {
                const {nonce} = await this._getSecurityValues();
                try {
                    const token = await this._exchangeCodeForToken({
                        grant_type: 'refresh_token',
                        redirect_uri: this._redirectURI,
                        client_id: this._clientId,
                        client_secret: this._clientSecret,
                        refresh_token: this._user.token.refresh_token,
                    }, connectorToken);
                    const {id_token} = token;

                    const {valid, decodedToken, error} = await this._validateIdToken(id_token, nonce);

                    if (!valid) {
                        reject({
                            i_apiErrorCode: openIDErrors.invalid_token,
                            i_apiErrorType: errorTypes.openIDErrors,
                            ...error,
                        });
                    }

                    if (this._dispatch) {
                        this._dispatch(setUserJWT(token));

                        if (this._cpxLogin) {
                            this._dispatch(getAction(
                                commands.updateCpxAuthenticationToken,
                                'updateCpxAuthenticationToken',
                                {
                                    s_authenticationToken: token.access_token,
                                }
                            ));
                        }
                    }

                    this._setUser({
                        ...this._user,
                        decoded_id_token: decodedToken,
                        token,
                    }, connectorToken);
                } catch (e) {
                    this._loggedIn = false;
                    this._user = null;

                    const url = new URL(window.location.href);
                    if (url.pathname !== "/") {
                        const error = createError(errorTypes.SoftwareErrors, softwareErrors.JWT_SESSION_EXPIRED);
                        if (this._dispatch) {
                            this._dispatch(logoutSuccess());
                            this._dispatch(setModalError({error}));
                        }
                        console.log('error jwt refresh', e);
                    }
                    // reject(e);
                }
            }
        });
    }
}
