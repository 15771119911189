import React from 'react';
import PropTypes from 'prop-types';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { connect } from 'react-redux';
import { getApiType } from 'dmpconnectjsapp-base/helpers/accessors';
import About from '../Header/About';
import env from '../../../envVariables';
import { formatVersion } from '../../../dmpconnect/utils/version';
import { API_TYPES } from '../../../dmpconnect/constants';

function Footer({ apiType, connectorVersion }) {
  const version = env.REACT_APP_VERSION;

  return (
    <div id="footer">
      <div className="text-center">
        <span>
          Efficience version
          {' '}
          {formatVersion(version)}
        </span>
        {' - '}
        <span>
          {apiType === API_TYPES.REST && 'DmpConnect-ES REST'}
          {apiType === API_TYPES.WS && 'DmpConnectJS'}
          {apiType === API_TYPES.JSON && 'DmpConnect-JSON'}
          {apiType !== API_TYPES.REST && connectorVersion ? ` version ${connectorVersion}` : ''}
        </span>
        {' - '}
        <span>
          Copyright 2000-2180, icanopée. Tous droits réservés
        </span>
        {' - '}
        <a
          href="https://www.icanopee.fr/politique-de-confidentialite/"
          target="_blank"
          rel="noopener noreferrer"
          className="typography-small-link"
        >
          Mentions légales & politique de confidentialité
        </a>
        {' - '}
        <About />
      </div>
    </div>
  );
}

Footer.defaultProps = {
  connectorVersion: null,
};

Footer.propTypes = {
  apiType: PropTypes.string.isRequired,
  connectorVersion: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.SESSION_SECTION]: {
        s_serviceVersion,
      },
    },
  } = state;

  return {
    apiType: getApiType(state),
    connectorVersion: s_serviceVersion,
  };
}

export default connect(mapStateToProps)(Footer);
