import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CenteredCard from './components/Common/Card/CenteredCard';
import Loading from './components/Common/Loading/Loading';
// Components
import {initApplication, resetAllConfigFromEnv, setPersistedAppConfiguration} from './dmpconnect/actions';

import { routes } from './router';
import VersionFooter from './components/Common/Version/VersionFooter';
import VersionFooterDP from './components/DP/VersionFooterDP';
import env, {generateEnvCheckSum} from "./envVariables";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import MssSendEmail from "./components/mss/MssSendEmail";
import IframeMessageReceiver from "./components/iframe/IframeMessageReceiver";
import {getApiType, getConfigurationValue, getDcParams} from "dmpconnectjsapp-base/helpers/accessors";
import CardReaderProvider from './components/Card/CardReaderProvider';
import EsConfigCheck from "./components/Config/EsConfigCheck";
import DpConnector from "./components/DP/DpConnector";
import AvailableNewVersion from './components/Common/Header/AvailableNewVersion';
import {isMssActive} from "./dmpconnect/helpers";
import userManager from "./dmpconnect/constants/userManager";
import lazyWithRetry from "./lib/lazyWithRetry";

const DMPDocument = lazyWithRetry(() => import('./scenes/DMPDocument'));
const DMPDocuments = lazyWithRetry(() => import('./scenes/DMPDocuments'));
const DMPSentDocuments = lazyWithRetry(() => import('./scenes/DMPSentDocuments'));
const InitApp = lazyWithRetry(() => import('./components/Init/InitApp'));
const DMPSearch = lazyWithRetry(() => import('./scenes/DMPSearch'));
const DPSearch = lazyWithRetry(() => import('./scenes/DPSearch'));
const DMPConfiguration = lazyWithRetry(() => import('./scenes/DMPConfiguration'));
const DMPDebug = lazyWithRetry(() => import('./scenes/DMPDebug'));
const DMPEsConfig = lazyWithRetry(() => import('./scenes/DMPEsConfig'));
const DMPTseConfig = lazyWithRetry(() => import('./scenes/DMPTseConfig'));
const ErrorModal = lazyWithRetry(() => import('./components/Error/ErrorModal'));
const GlobalModalConfirmation = lazyWithRetry(() => import('./components/Common/Modal/GlobalModalConfirmation'));
const DMPAdministration = lazyWithRetry(() => import('./scenes/DMPAdministration'));
const WebAccessPDFViewer = lazyWithRetry(() => import('./scenes/WebAccessPDFViewer'));
const PageNotFound = lazyWithRetry(() => import('./scenes/PageNotFound'));
const DMPAccess = lazyWithRetry(() => import('./scenes/DMPAccess'));
const VirtualPrinterOverlay = lazyWithRetry(() => import('./components/VirtualPrinter/VirtualPrinterOverlay'));
const RemoteCreateDMP = lazyWithRetry(() => import('./components/Remote/RemoteCreateDMP'));
const RemoteReactivateDMP = lazyWithRetry(() => import('./components/Remote/RemoteReactivateDMP'));
const RemoteOpenDMPConsent = lazyWithRetry(() => import('./components/Remote/RemoteOpenDMPConsent'));
const RemoteCloseDMPConsent = lazyWithRetry(() => import('./components/Remote/RemoteCloseSessionDMPConsent'));
const RemoteSubmitDocument = lazyWithRetry(() => import('./components/Remote/RemoteSubmitDocument'));
const ListView = lazyWithRetry(() => import('./scenes/ParcoursSoin'));
const EsLoginCallback = lazyWithRetry(() => import('./components/DMPLogin/EsLoginCallback'));

const DPAccess = lazyWithRetry(() => import('./scenes/DPAccess'));
const DPList = lazyWithRetry(() => import('./scenes/DPList'));

const ALDVitale = lazyWithRetry(() => import('./scenes/ALDVitale'));
const ALDDmp = lazyWithRetry(() => import('./scenes/ALDDmp'));

const MssClient = lazyWithRetry(() => import('./scenes/MssClient'));
const DMPLogout = lazyWithRetry(() => import('./components/DMPLogin/DMPLogout'));

const DISPLAY_MODES = {
  EFFICIENCE: 'EFFICIENCE',
  MSSCLIENT: 'MSSCLIENT',
  DPCONSULT: 'DPCONSULT',
}

const App = ({
  dispatch, saasTokenOk, envCheckSum, openIDConfiguration,
  applicationInitialized,
  showMssPopup,
  mssActive,
  efficienceDP,
  hideConfig,
  connectorToken,
  apiType,
  dcParams,
  pathname,
  urlProcessed,
  displayMode,
}) => {
  React.useEffect(() => {
    document.title = 'Efficience';
  }, []);

  React.useEffect(() => {
    const currentEnvChecksum = generateEnvCheckSum(env);
    if (envCheckSum !== currentEnvChecksum) {
      dispatch(resetAllConfigFromEnv());
      dispatch(setPersistedAppConfiguration('envCheckSum', currentEnvChecksum));
    }
  }, [env, envCheckSum]);

  React.useEffect(() => {
    if (saasTokenOk && urlProcessed && pathname !== '/logout') {
      dispatch(initApplication());
    }
  }, [saasTokenOk, urlProcessed]);

  React.useEffect(() => {
    userManager.setConfig({
      ...openIDConfiguration,
      connectorToken,
      apiType,
      dcParams: openIDConfiguration.sendTokenDcParams === true ? dcParams : undefined,
    });
  }, [openIDConfiguration, connectorToken, apiType, dcParams]);


  return (
    <ErrorBoundary>
      <Suspense fallback={
        <CenteredCard><Loading message="Chargement ..." /></CenteredCard>
      }>
        <AvailableNewVersion />
        <Switch>
          <Route path={routes.logout.path} component={DMPLogout} />
          {Number(env.REACT_APP_DEBUG) === 1 && (
            <Route exact path={routes.debug.path} component={DMPDebug} />
          )}
          <Route exact path={routes.tseConfig.path} component={DMPTseConfig} />
          <Route path="/*">
            <>
              {saasTokenOk ? (
                <>
                  {applicationInitialized ? (
                    <CardReaderProvider showLoading={false}>
                        <Switch>
                          <Route exact path="/authcallback" component={EsLoginCallback} />
                          <Route exact path={routes.esConfig.path} component={DMPEsConfig} />
                          <Route path="/*">
                            <>
                            {displayMode === DISPLAY_MODES.MSSCLIENT && (
                              <EsConfigCheck>
                                <DMPAccess>
                                  <Switch>
                                    <Route
                                      exact
                                      path={routes.configuration.path}
                                      component={hideConfig ? PageNotFound : DMPConfiguration}
                                    />
                                    <Route path={routes.mss.path} component={MssClient} />
                                    <Route path="/*" component={PageNotFound} />
                                  </Switch>
                                  {showMssPopup && mssActive === true && <MssSendEmail />}
                                </DMPAccess>
                              </EsConfigCheck>
                            )}
                            {displayMode === DISPLAY_MODES.DPCONSULT && (
                              <DPAccess>
                                <DpConnector>
                                  <Switch>
                                    <Route exact path={routes.dmpSearch.path} component={DPSearch} />
                                    <Route path={routes.dp.path} component={DPList} />
                                    <Route
                                      exact
                                      path={routes.configuration.path}
                                      component={hideConfig ? PageNotFound : DMPConfiguration}
                                    />
                                    <Route path="/*" component={PageNotFound} />
                                  </Switch>
                                </DpConnector>
                              </DPAccess>
                            )}
                            {displayMode === DISPLAY_MODES.EFFICIENCE && (
                              <EsConfigCheck>
                                <DMPAccess>
                                  <Switch>
                                    <Route path={routes.dmpSearch.path} component={DMPSearch} />
                                    <Route exact path={routes.aldVitale.path} component={ALDVitale} />
                                    <Route exact path={routes.aldDmp.path} component={ALDDmp} />
                                    <Route
                                      exact
                                      path={routes.configuration.path}
                                      component={hideConfig ? PageNotFound : DMPConfiguration}
                                    />
                                    <Route exact path={routes.dmpDocument.path} component={DMPDocument} />
                                    <Route path={routes.dmpAdministration.path} component={DMPAdministration} />
                                    <Route path={routes.dmpDocumentsCategory.path} component={DMPDocuments} />
                                    <Route path={routes.dmpDocuments.path} component={DMPDocuments} />
                                    <Route path={routes.webAccessPDFViewer.path} component={WebAccessPDFViewer} />
                                    <Route path={routes.listview.path} component={ListView} />
                                    <Route path={routes.mss.path} component={MssClient} />
                                    <Route path={routes.sentDocuments.path} component={DMPSentDocuments} />
                                    <Route path="/*" component={PageNotFound} />
                                  </Switch>
                                  <RemoteCreateDMP />
                                  <RemoteReactivateDMP />
                                  <RemoteOpenDMPConsent />
                                  <RemoteCloseDMPConsent />
                                  <RemoteSubmitDocument />
                                  <VirtualPrinterOverlay />
                                  {showMssPopup && mssActive === true && <MssSendEmail />}
                                </DMPAccess>
                              </EsConfigCheck>
                            )}
                            </>
                          </Route>
                        </Switch>
                    </CardReaderProvider>
                  ) : (
                    <InitApp />
                  )}
                </>
              ) : (
                <CenteredCard>
                  Vous n'&ecirc;tes pas autorisé à utiliser cette application.
                </CenteredCard>
              )}
            </>
          </Route>
        </Switch>

        <ErrorModal />
        <GlobalModalConfirmation />
        {displayMode === DISPLAY_MODES.DPCONSULT ? (
          <VersionFooterDP />
        ) : (
          <VersionFooter />
        )}
        <ToastContainer
          autoClose={2000}
          position={'top-right'}
          closeOnClick
          hideProgressBar
        />
        <IframeMessageReceiver />
      </Suspense>
    </ErrorBoundary>
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  applicationInitialized: PropTypes.bool,
  showMssPopup: PropTypes.bool,
  mssActive: PropTypes.bool,
  saasTokenOk: PropTypes.bool,
  hideConfig: PropTypes.bool,
  envCheckSum: PropTypes.string,
  openIDConfiguration: PropTypes.object,
  connectorToken: PropTypes.string,
  dcParams: PropTypes.string,
  apiType: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  urlProcessed: PropTypes.bool,
  displayMode: PropTypes.string.isRequired,
};

App.defaultProps = {
  applicationInitialized: false,
  showMssPopup: false,
  mssActive: false,
  saasTokenOk: false,
  hideConfig: false,
  envCheckSum: null,
  openIDConfiguration: null,
  connectorToken: undefined,
  dcParams: undefined,
  urlProcessed: false,
};

function mapStateToProps(state) {
  const {
    router: { location: { pathname } },
    dmpconnectInit: {
      applicationInitialized,
    },
    dmpconnectApplication: {
      showMssPopup,
      saasTokenOk,
      urlProcessed,
    },
    dmpConnectPersistedAppConfiguration: {
      efficienceDP,
      mssClientMode,
      hideConfig,
      envCheckSum,
    },
    openIDConfiguration,
    dmpconnectPersistedConnectorConfiguration,
  } = state;

  let displayMode = DISPLAY_MODES.EFFICIENCE;
  if (efficienceDP) displayMode = DISPLAY_MODES.DPCONSULT;
  else if(mssClientMode) displayMode = DISPLAY_MODES.MSSCLIENT;

  return {
    pathname,
    urlProcessed,
    applicationInitialized,
    saasTokenOk,
    showMssPopup,
    openIDConfiguration,
    apiType: getApiType(state),
    dcParams: getDcParams(state),
    mssActive: isMssActive(state),
    efficienceDP,
    hideConfig,
    envCheckSum,
    connectorToken: getConfigurationValue('connectorJWT', dmpconnectPersistedConnectorConfiguration),
    displayMode,
  };
}

const connectedApp = connect(mapStateToProps,
  null,
  null,
  {
    pure: false,
  })(App);

export default connectedApp;
