import { put, take } from 'redux-saga/effects';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import { formatSetPersistantDataParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import moment from 'moment';
import { getAction } from '../actions';
import { b64DecodeUnicode, b64EncodeUnicode } from '../utils/dataUtils';


export function* saveStatInPersistantData(action) {
  yield put(getAction(
    commands.getPersistantData,
    apiSections.GET_PERSISTANT_DATA,
  ));
  const { data: { s_persistantData64 } } = yield take(dmpCommandSuccessContextualizedType(apiSections.GET_PERSISTANT_DATA));
  let alim;
  let consult;
  let startDate;
  let persistantDataRest;
  try {
    const currentData = s_persistantData64 ? JSON.parse(b64DecodeUnicode(s_persistantData64)) : {};

    const {
      statistics: {
        alim: persistedAlim = 0,
        consult: persistedConsult = 0,
        startDate: persistedStartDate = moment().format('YYYYMMDD'),
      } = {},
      ...persistantData
    } = currentData;

    alim = persistedAlim;
    consult = persistedConsult;
    startDate = persistedStartDate;
    persistantDataRest = persistantData;
  } catch (e) {
    alim = 0;
    consult = 0;
    startDate = moment().format('YYYYMMDD');
    persistantDataRest = null;
  }

  yield put(getAction(
    commands.setPersistantData,
    apiSections.SET_PERSISTANT_DATA,
    formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify({
      ...persistantDataRest,
      statistics: {
        alim: action.type === dmpCommandSuccessContextualizedType(apiSections.SEND_DOCUMENT_SECTION) ? alim + 1 : alim,
        consult: dmpCommandSuccessContextualizedType(apiSections.DOCUMENT_CONTENT_SECTION) ? consult + 1 : consult,
        startDate,
      },
    }))),
  ));
}
