import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import merge from 'lodash.merge';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import env from '../../../envVariables';
import { insOidToType, insTypes, insTypeToOid } from '../../constants/dmpConstants';
import { insTypeByVitaleCardStatus } from '../vitaleCard';
import { getPatientInsiIdentityFromAccessibleDMPsCache } from '../accessibleDMPList';

export const getQualifiedTD0Ins = certifiedIdentity => `${certifiedIdentity.Ins.s_ins}${certifiedIdentity.Ins.s_insType}`;
export const getQualifiedInsINSi = (insIdentity, vitaleCardStatus) => {
  const {
    Identity: {
      Ins: {
        s_value: ins,
        s_key: key,
        s_oid: oid,
      } = {},
    } = {},
  } = insIdentity;

  // if not production, but we received a production ins => force a test one
  // (error with INSi services which returns prod oids instead of test ones on qualif server)
  if (Number(env.REACT_APP_PRODUCTON_MODE) !== 1 && ![insTypes.DEMO_INS, insTypes.TEST_INS].includes(insOidToType[oid])) {
    return `${ins}${key}${vitaleCardStatus ? insTypeByVitaleCardStatus[vitaleCardStatus] : insTypes.TEST_INS}`;
  }
  return `${ins}${key}${insOidToType[oid]}`;
};
export const fixQualifIdentityOid = (identity, vitaleCardStatus) => {
  if (!identity) return undefined;

  const {
    Ins: {
      s_oid: oid,
    } = {},
  } = identity;

  // if not production, but we received a production ins => force a test one
  // (error with INSi services which returns prod oids instead of test ones on qualif server)
  if (Number(env.REACT_APP_PRODUCTON_MODE) !== 1 && ![insTypes.DEMO_INS, insTypes.TEST_INS].includes(insOidToType[oid])) {
    const newOid = insTypeToOid[vitaleCardStatus ? insTypeByVitaleCardStatus[vitaleCardStatus] : insTypes.TEST_INS];

    return merge({}, identity, { Ins: { s_oid: newOid } });
  }
  return identity;
};

export const getVitaleIndex = (subSection) => {
  const splittedCertIndex = subSection.split('_');
  if (splittedCertIndex.length === 2) {
    return Number(splittedCertIndex[1]);
  }
  return undefined;
};

export const isInsCertified = (certifiedIdentitySection, ins) => {
  if (!certifiedIdentitySection) return false;
  return Object.entries(certifiedIdentitySection).some((patient) => {
    const values = patient[1];
    if (!values || !isReady(values)) return false;
    const { Ins: { s_ins, s_insType } } = values;
    const qualifiedIns = s_ins + s_insType;
    return ins === qualifiedIns;
  });
};

export function getVitaleIndexFromCertifiedIdentity(certifiedIdentity = {}, ins) {
  const certId = Object.entries(certifiedIdentity).find(([qualifiedInsFromVitaleCard, currentIdentity]) => {
    if (!currentIdentity || !currentIdentity.Ins) return undefined;
    const { Ins: { s_ins, s_insType } } = currentIdentity;
    return `${s_ins}${s_insType}` === ins;
  });

  if (certId) {
    return getVitaleIndex(certId[0]);
  }

  return undefined;
}

export const getCertifiedTD0IdentitySubSectionFromIns = (identities = {}, ins) => (
  Object.values(identities).find((currentIdentity) => {
    if (!currentIdentity || !currentIdentity.Ins) return undefined;
    return getQualifiedTD0Ins(currentIdentity) === ins;
  })
);

export const getCertifiedInsIdentitySubSectionFromIns = (insiIdentities = {}, ins) => {
  const identity = Object.values(insiIdentities).find((currentIdentity) => {
    if (!currentIdentity || !currentIdentity.Identity || !currentIdentity.Identity.Ins) return undefined;
    return getQualifiedInsINSi(currentIdentity) === ins;
  });

  if (identity) {
    return { ...identity, Identity: fixQualifIdentityOid(identity.Identity) };
  }

  return undefined;
};

export const getCertifiedIdentityFromIns = (state, ins) => {
  const {
    dmpconnect: {
      [apiSections.INS_FROM_VITALE_CARD]: insiIdentitiesSection = {},
    },
  } = state;

  let identity;
  const cachedIdentity = getPatientInsiIdentityFromAccessibleDMPsCache(state, ins);

  if (cachedIdentity) {
    identity = { ...cachedIdentity, Identity: fixQualifIdentityOid(cachedIdentity.Identity) };
  } else {
    identity = getCertifiedInsIdentitySubSectionFromIns(insiIdentitiesSection, ins);
  }

  return identity;
};
