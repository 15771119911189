import { informantRelationType } from '../../constants/informants';

export function extractAdresses(informant) {
  let addressesArray;
  if (!Array.isArray(informant.addresses.address)) {
    addressesArray = [informant.addresses.address];
  } else {
    addressesArray = informant.addresses.address;
  }
  return addressesArray.map(address => ({
    i_type: Number(address.type.value),
    s_country: address.country.value,
    s_state: address.state ? address.state.value : '',
    s_city: address.city.value,
    s_postalCode: address.postalCode.value,
    s_houseNumber: address.houseNumber ? address.houseNumber.value : '',
    s_houseNumberNumeric: address.houseNumberNumeric ? address.houseNumberNumeric.value : '',
    i_streetNameType: Number(address.streetNameType.value),
    s_streetName: address.streetName.value,
    s_additionalLocator: address.additionalLocator ? address.additionalLocator.value : '',
    s_unitId: address.unitId ? address.unitId : '',
    s_postBox: address.postBox ? address.postBox : '',
    s_precInct: address.precInct ? address.precInct : '',
  }));
}

export function extractTelecoms(informant) {
  let telecomsArray;
  if (!Array.isArray(informant.telecoms.telecom)) {
    telecomsArray = [informant.telecoms.telecom];
  } else {
    telecomsArray = informant.telecoms.telecom;
  }
  return telecomsArray.map(telecom => ({
    i_type: Number(telecom.type.value),
    i_usage: telecom.usage ? Number(telecom.usage.value) : 512,
    s_value: telecom.value.value,
  }));
}

export function extractEventCodes(params) {
  const { document: { EventCodes } = {} } = params;

  let parsedEventCodes;

  if (EventCodes && EventCodes.EventCode) {
    let eventCodes;
    if (!Array.isArray(EventCodes.EventCode)) {
      eventCodes = [EventCodes.EventCode];
    } else {
      eventCodes = EventCodes.EventCode;
    }

    parsedEventCodes = eventCodes.map(eventCode => ({
      code: eventCode.code.value,
      classification: eventCode.classification.value,
      description: eventCode.description.value,
    }));
  }
  return parsedEventCodes;
}

export function extractAdditionalAuthors(document) {
  const { additionalAuthors } = document;
  let parsedAdditionalAuthors;
  if (additionalAuthors && additionalAuthors.additionalAuthor) {
    let authors;
    if (!Array.isArray(additionalAuthors.additionalAuthor)) {
      authors = [additionalAuthors.additionalAuthor];
    } else {
      authors = additionalAuthors.additionalAuthor;
    }

    parsedAdditionalAuthors = authors.map(author => ({
      s_hpName: author.name.value,
      s_hpGiven: author.given.value,
      s_hpProfession: author.profession.value,
      s_hpProfessionOid: author.professionOid.value,
      s_hpSpecialty: author.specialty.value,
      s_hpInternalId: author.internalId.value,
      s_hpInternalIdType: author.internalIdType ? author.internalIdType.value : undefined,
      i_hpAuthenticationMode: author.authenticationMode ? Number(author.authenticationMode.value) : 25,
    }));
  }
  return parsedAdditionalAuthors;
}

export function extractInformants(document) {
  const { informants } = document;
  let parsedInformants;
  if (informants && informants.informant) {
    let informantsArray;
    if (!Array.isArray(informants.informant)) {
      informantsArray = [informants.informant];
    }

    parsedInformants = informantsArray.map((informant) => {
      let addresses;
      let telecoms;

      if (informant.addresses && informant.addresses.address) {
        addresses = extractAdresses(informant);
      }
      if (informant.telecoms && informant.telecoms.telecom) {
        telecoms = extractTelecoms(informant);
      }
      return ({
        s_name: informant.name.value,
        s_given: informant.given.value,
        i_type: Number(informant.type.value),
        i_relationType: informant.relationType ? Number(informant.relationType.value) : informantRelationType.unknown,
        Addresses: addresses,
        Telecoms: telecoms,
      });
    });
  }
  return parsedInformants;
}

export function extractIntendedRecipients(document) {
  const { intendedRecipients } = document;
  let parsedIntendedRecipients;
  if (intendedRecipients && intendedRecipients.intendedRecipient) {
    let intendedRecipientsArray;
    if (!Array.isArray(intendedRecipients.intendedRecipient)) {
      intendedRecipientsArray = [intendedRecipients.intendedRecipient];
    }

    parsedIntendedRecipients = intendedRecipientsArray.map((intendedRecipient) => {
      let addresses;
      let telecoms;

      if (intendedRecipient.addresses && intendedRecipient.addresses.address) {
        addresses = extractAdresses(intendedRecipient);
      }
      if (intendedRecipient.telecoms && intendedRecipient.telecoms.telecom) {
        telecoms = extractTelecoms(intendedRecipient);
      }
      return ({
        s_hpName: intendedRecipient.name.value,
        s_hpGiven: intendedRecipient.given.value,
        s_hpProfession: intendedRecipient.profession ? intendedRecipient.profession.value : undefined,
        s_hpProfessionOid: intendedRecipient.professionOid ? intendedRecipient.professionOid.value : undefined,
        s_hpSpecialty: intendedRecipient.specialty ? intendedRecipient.specialty.value : undefined,
        s_hpInternalId: intendedRecipient.internalId.value,
        s_hpInternalIdType: intendedRecipient.internalIdType ? intendedRecipient.internalIdType.value : undefined,
        i_hpAuthenticationMode: intendedRecipient.authenticationMode ? Number(intendedRecipient.authenticationMode.value) : 25,
        Addresses: addresses,
        Telecoms: telecoms,
      });
    });
  }
  return parsedIntendedRecipients;
}

export function extractTreatingPhysician(document) {
  if (document.treatingPhysician) {
    const {
      treatingPhysician: {
        name: { value: treatingPhycisianName } = {},
        given: { value: treatingPhycisianGiven } = {},
        profession: { value: treatingPhycisianProfession } = {},
        professionOid: { value: treatingPhycisianProfessionOid } = {},
        specialty: { value: treatingPhycisianSpecialty } = {},
        internalId: { value: treatingPhycisianInternalId } = {},
        internalIdType: { value: treatingPhycisianInternalIdType } = {},
        authenticationMode: { value: treatingPhycisianAuthenticationMode } = {},
      } = {},
    } = document;

    return {
      s_hpName: treatingPhycisianName,
      s_hpGiven: treatingPhycisianGiven,
      s_hpProfession: treatingPhycisianProfession,
      s_hpProfessionOid: treatingPhycisianProfessionOid,
      s_hpSpecialty: treatingPhycisianSpecialty,
      s_hpInternalId: treatingPhycisianInternalId,
      s_hpInternalIdType: treatingPhycisianInternalIdType,
      i_hpAuthenticationMode: treatingPhycisianAuthenticationMode,
    };
  }
  return undefined;
}

export function extractPerformer(document) {
  if (document.performer) {
    const {
      performer: {
        name: { value: performerName } = {},
        given: { value: performerGiven } = {},
        profession: { value: performerProfession } = {},
        professionOid: { value: performerProfessionOid } = {},
        specialty: { value: performerSpecialty } = {},
        internalId: { value: performerInternalId } = {},
        internalIdType: { value: performerInternalIdType } = {},
        authenticationMode: { value: performerAuthenticationMode } = {},
      } = {},
    } = document;

    return {
      Hp: {
        s_hpName: performerName,
        s_hpGiven: performerGiven,
        s_hpProfession: performerProfession,
        s_hpProfessionOid: performerProfessionOid,
        s_hpSpecialty: performerSpecialty,
        s_hpInternalId: performerInternalId,
        s_hpInternalIdType: performerInternalIdType,
        i_hpAuthenticationMode: performerAuthenticationMode,
      },
    };
  }

  return undefined;
}
