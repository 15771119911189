import { mssActionConstants } from 'dmpconnectjsapp-base/constants';
import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants, dmpconnectRemoteActionConstants, mssActions } from '../constants';

const initialState = {
  mssApiType: undefined,
  users: {},
  // [MssEmail]: {
  //   MssFolders: [],
  //   MssMessages: [],
  //   MssSyncToken: '',
  //   mssSearchedMessageIds: null or [],
  // }
};

function setFolderSynced(folders, folderId) {
  folders.forEach((f, idx) => {
    if (f.id === folderId) {
      folders[idx].isSynced = true;
    }
    if (f.folders.length > 0) {
      folders[idx].folders = setFolderSynced(f.folders, folderId);
    }
  });
  return folders;
}

function syncMessages(state, emailAddress, messages) {
  const {
    [emailAddress]: {
      MssFolders = [],
      MssMessages = [],
    } = {},
    ...othersAccounts
  } = state;
  let newMessages = MssMessages;

  if (messages.allMessages) {
    const keepOtherFolders = newMessages.filter(m => m.folderId !== messages.folderId);
    newMessages = [...keepOtherFolders, ...messages.allMessages.map((message) => {
      const originalMessage = MssMessages.find(om => om.messageId === message.messageId);
      if (originalMessage) {
        return merge({}, originalMessage, message);
      }
      return message;
    })];
  } else {
    if (messages.deletedMessageIds.length > 0) {
      // keep all messages where messageId id not in deleted list
      newMessages = newMessages.filter(message => messages.deletedMessageIds.indexOf(message.messageId) === -1);
    }

    if (messages.modifiedMessages.length > 0) {
      const modifiedIds = messages.modifiedMessages.map(message => message.messageId);
      // keep all messages not modified
      newMessages = newMessages.filter(message => modifiedIds.indexOf(message.messageId) === -1);
      // insert modified messages
      newMessages = newMessages.concat(messages.modifiedMessages);
    }
  }

  return {
    ...othersAccounts,
    [emailAddress]: {
      MssMessages: newMessages,
      MssFolders: messages.folderId ? setFolderSynced(MssFolders, messages.folderId) : MssFolders,
      MssSyncToken: messages.token,
    },
  };
}

function markMessageAsRead(state, emailAddress, messageIds, read) {
  const {
    [emailAddress]: {
      MssMessages = [],
      ...account
    } = {},
    ...othersAccounts
  } = state;

  return {
    ...othersAccounts,
    [emailAddress]: {
      ...account,
      MssMessages: MssMessages.map(message => (
        messageIds.includes(message.messageId)
          ? { ...message, flags: { ...message.flags, unread: !read } }
          : message
      )),
    },
  };
}

function moveMessages(state, emailAddress, messageIds, destinationFolderId) {
  const {
    [emailAddress]: {
      MssMessages = [],
      ...account
    } = {},
    ...othersAccounts
  } = state;

  return {
    ...othersAccounts,
    [emailAddress]: {
      ...account,
      MssMessages: MssMessages.map(message => (
        messageIds.includes(message.messageId)
          ? { ...message, folderId: destinationFolderId }
          : message
      )),
    },
  };
}

function deleteMessages(state, emailAddress, messageIds) {
  const {
    [emailAddress]: {
      MssMessages = [],
      ...account
    } = {},
    ...othersAccounts
  } = state;

  return {
    ...othersAccounts,
    [emailAddress]: {
      ...account,
      MssMessages: MssMessages.filter(message => !messageIds.includes(message.messageId)),
    },
  };
}

const setMessageRemoteExportingStatus = (state, emailAddress, messageId, status) => {
  const {
    [emailAddress]: {
      MssMessages = [],
      ...account
    } = {},
    ...othersAccounts
  } = state;
  return {
    ...othersAccounts,
    [emailAddress]: {
      ...account,
      MssMessages: MssMessages.map(message => (
        message.messageId === messageId
          ? { ...message, remoteExporting: status }
          : message
      )),
    },
  };
};

const updateMessageContent = (state, action) => {
  const {
    email, messageId, type, ...newContent
  } = action;
  const {
    [email]: {
      MssMessages = [],
      ...account
    } = {},
    ...othersAccounts
  } = state;

  return {
    ...othersAccounts,
    [email]: {
      ...account,
      MssMessages: MssMessages.map((message) => {
        if (message.messageId === messageId) {
          return { ...message, ...newContent };
        }
        return message;
      }),
    },
  };
};

export function mssMessages(state = initialState, action) {
  switch (action.type) {
    case mssActionConstants.EMPTY_ALL_MSS_MESSAGES:
      return initialState;
    case mssActionConstants.SET_MSS_MESSAGES_API_TYPE:
      return { ...state, mssApiType: action.apiType };
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES:
      return syncMessages(state, action.emailAddress, action.messages);
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_FOLDERS:
      return {
        ...state,
        [action.emailAddress]: {
          ...state[action.emailAddress],
          MssFolders: action.folders,
        },
      };
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_READ:
      return markMessageAsRead(state, action.emailAddress, action.messageIds, action.read);

    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_MOVED:
      return moveMessages(state, action.emailAddress, action.messageIds, action.destinationFolderId);

    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MESSAGE_DELETED:
      return deleteMessages(state, action.emailAddress, action.messageIds);

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_EMAIL:
      return setMessageRemoteExportingStatus(state, action.email.mssEmail, action.email.messageId, true);
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_EMAIL_DONE:
      return setMessageRemoteExportingStatus(state, action.email.mssEmail, action.email.messageId, false);
    case mssActionConstants.SET_MESSAGE_CONTENT:
      return updateMessageContent(state, action);
    case mssActions.SET_MSS_SEARCHED_MESSAGE_IDS:
      return {
        ...state,
        [action.mssEmail]: {
          ...state[action.mssEmail],
          mssSearchedMessageIds: action.messageIds,
        },
      };
    default:
      return state;
  }
}
