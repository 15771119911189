import moment from 'moment/moment';
import { dmpSexes } from '../../constants/dmpConstants';

export const extractInsiIdentity = (object) => {
  if (object.Identity) {
    const {
      Identity: {
        Ins: {
          value: {
            value: insValue,
          } = {},
          key: {
            value: insKey,
          } = {},
          oid: {
            value: insOid,
          } = {},
          startDate,
          endDate,
        } = {},
        lastInsiCallDate,
        birthName: {
          value: birthName,
        } = {},
        given,
        birthGiven: {
          value: birthgiven,
        } = {},
        sex: {
          value: sex,
        } = {},
        birthDate: {
          value: birthDate,
        } = {},
        birthPlace,
      } = {},
    } = object;

    return {
      Ins: {
        s_value: insValue,
        s_key: insKey,
        s_oid: insOid,
        s_startDate: startDate && startDate.value ? startDate.value : '',
        s_enDate: endDate && endDate.value ? endDate.value : '',
      },
      s_lastInsiCallDate: lastInsiCallDate && lastInsiCallDate.value
        ? moment(lastInsiCallDate.value, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD'], true).format('YYYY-MM-DD')
        : '',
      s_birthName: birthName || '',
      s_given: given && given.value ? given.value : '',
      s_birthGiven: birthgiven || '',
      i_sex: Number(sex) || dmpSexes.UNKNOWN,
      s_birthDate: birthDate
        ? moment(birthDate, ['DD/MM/YYYY', 'YYYYMMDD', 'YYYY-MM-DD'], true).format('YYYY-MM-DD')
        : '',
      s_birthPlace: birthPlace && birthPlace.value ? birthPlace.value : '',
    };
  }

  return undefined;
};
