import JSum from 'jsum';

const processEnv = typeof process !== 'undefined' ? process.env : {};
const injectedEnv = window && window.injectedEnv ? window.injectedEnv : {};
export const appConfig = window && window.appConfig ? window.appConfig : {};

const overridableParams = [
  'REACT_APP_DMP_SERVER_NAME',
  'REACT_APP_WEB_PS_BASE_URL',
  'REACT_APP_DMPCONNECTJS_PORT',
  'REACT_APP_API_TYPE',
  'REACT_APP_DCPARAMS',

  'REACT_APP_CONSULT_ONLY',
  'REACT_APP_TSE',
  'REACT_APP_SHOW_AIR_STATS',
  'REACT_APP_AIR_NO_ES',
  'REACT_APP_ES_REST_EXPORT_CDA_DOCUMENT',
  'REACT_APP_SAAS_TOKEN',
  'REACT_APP_VSM_AUTO_OPEN',
  'REACT_APP_SHOW_DOC_CAT_IN_LIST',
  'REACT_APP_DMP_LANDING_PAGE',
  'REACT_APP_LICENSE_FORM_URL',
  'REACT_APP_HIDE_CONNECTOR_NEW_VERSION_MESSAGE',
  'REACT_APP_HIDE_CONFIG', // cacher le bouton d'accès aux paramètres
  'REACT_APP_HIDE_MAILIZ', // cacher le bouton d'accès à mailiz
  'REACT_APP_DEACTIVATE_DASHBOARD', // désactiver l'accès au dashboard -> accès direct au DMP via ouverture contextuelle
  'REACT_APP_MINIMUM_MINOR_AGE', // définit l'age minimum de majorité,
  'REACT_APP_HIDE_NEWS_SIDEBAR', // cacher la barre latérale des actualités Efficience
  'REACT_APP_FORCE_SCHEMATRONS_VALIDATION',
  'REACT_APP_PRODUCTON_MODE',
  'REACT_APP_PDFA1_CONVERSION_DISABLE',
  'REACT_APP_PDFA1_CONVERSION_IGNORE_TRANSPARENCY',
  'REACT_APP_LOG_LEVEL', // 0 : no log, 1 : display console.log
  'REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE', // message d'erreur custom sur l'écran indiquant que le connecteur est introuvable

  'REACT_APP_ES_REST_HOST',
  'REACT_APP_ES_REST_PORT',
  'REACT_APP_ES_REST_URL',

  'REACT_APP_ES',
  'REACT_APP_ES_ID',
  'REACT_APP_ES_ID_MSS',
  'REACT_APP_ES_FINESS',
  'REACT_APP_ES_CERT',
  'REACT_APP_ES_CERT_MSS',
  'REACT_APP_ES_LOCATION_NAME',
  'REACT_APP_ES_ACTIVITY_SECTOR',
  'REACT_APP_ES_PRACTICE_SETTING',
  'REACT_APP_ES_HEALTHCARE_SETTING',
  'REACT_APP_ES_LOGIN_TYPE',
  'REACT_APP_ES_AUTHENTICATION_MODE',
  'REACT_APP_ES_AUTHENTICATION_CONTEXT',
  'REACT_APP_ES_CPX_LOGIN_ACTIVE',

  'REACT_APP_ES_LOGIN_OPENID_CLIENT_ID',
  'REACT_APP_ES_LOGIN_OPENID_CLIENT_SECRET',
  'REACT_APP_ES_LOGIN_OPENID_SIGN_KEY',
  'REACT_APP_ES_LOGIN_OPENID_PKCE',
  'REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_TOKEN_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_ENDSESSION_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_METHOD',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_METHOD',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_MAPPER',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS',
  'REACT_APP_ES_LOGIN_OPENID_PSC_CONFIG_FROM_DMPCONNECTJS',
  'REACT_APP_ES_LOGIN_OPENID_ACRVALUES',
  'REACT_APP_ES_LOGIN_OPENID_SCOPE',
  'REACT_APP_ES_LOGIN_OPENID_REDIRECTION_URL',

  'REACT_APP_ES_LOGIN_CHECK',
  'REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES',
  'REACT_APP_ES_LOGIN_CHECK_MAPPING',
  'REACT_APP_ES_LOGIN_CHECK_API', // 1 ou 0, active la récupération des infos de vérification par API
  'REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT', // url, appel en POST
  'REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PS_PARAM', // nom du paramètre auquel on passe l'id ps
  'REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PATIENT_PARAM', // nom du paramètre auquel on passe l'id patient

  'REACT_APP_ES_LOGIN_API_ENDPOINT',
  'REACT_APP_ES_LOGIN_API_ENDPOINT_PS_PARAM',
  'REACT_APP_ES_LOGIN_API_ENDPOINT_PATIENT_PARAM',
  'REACT_APP_ES_LOGIN_API_EXTRA_HEADER',
  'REACT_APP_API_LOGIN_MOCK',

  'REACT_APP_ES_JWT_KEY',
  'REACT_APP_GENERATED_CONNECTOR_JWT',

  'REACT_APP_REMOTE_CONTROL',
  'REACT_APP_REMOTE_CONTROL_APP_NAME',
  'REACT_APP_REMOTE_CONTROL_AUTO_EXPORT_VITALE_DATA',
  'REACT_APP_HIDE_REMOTE_CONTROL_NOTIFICATIONS',
  'REACT_APP_MESSAGE_EVENT_TARGET_ORIGIN', // origin de la fenêtre pilotant Efficience par Iframe
  'REACT_APP_REMOTE_CONTROL_EXPORT_CHANNEL', // messageEvent / post / connector
  'REACT_APP_REMOTE_CONTROL_EXPORT_CHANNEL_URL',
  'REACT_APP_REMOTE_CONTROL_DISABLE_EXPORTS',

  'REACT_APP_DP_ETAB_TYPE',
  'REACT_APP_DP_ETAB_IDENTIFIER',
  'REACT_APP_DP_SOFTWARE_VALIDATION_CODE',
  'REACT_APP_DP_SOFTWARE_VERSION',
  'REACT_APP_DP_SOFTWARE_PATCH',
  'REACT_APP_DP_SERVER_NAME',
  'REACT_APP_DP_EXPORT_URL',

  'REACT_APP_MSS',
  'REACT_APP_MSS_IMAP_SERVER',
  'REACT_APP_MSS_IMAP_PORT',
  'REACT_APP_MSS_SMTP_SERVER',
  'REACT_APP_MSS_SMTP_PORT',
  'REACT_APP_MSS_SMTP_PORT',
  'REACT_APP_MSS_LOGIN_TYPE',
  'REACT_APP_MSS_RECEIPT_NOTIFICATION_TYPE', // type d'accusé de réception MSS (DSN, MDN)
  'REACT_APP_MSS_API_TYPE', // IMAP, WEB
  'REACT_APP_MSS_CLIENT_MODE', // mode client mss
];

const filteredAppConfig = Object.assign(
  {},
  ...overridableParams.map(key => (appConfig[key] !== undefined ? ({ [key]: appConfig[key] }) : undefined)),
);

const env = {
  ...processEnv,
  ...injectedEnv,
  ...filteredAppConfig,
};

export const generateEnvCheckSum = (currentEnv) => {
  const { REACT_APP_VERSION, ...curEnv } = currentEnv;
  return JSum.digest(curEnv, 'SHA256', 'hex');
};

export default env;
