import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { apiSections, mssLoginTypes, mssReceiptNotificationTypes } from 'dmpconnectjsapp-base/constants';
import {
  getConfigurationValue,
  getMssEmail,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { toast } from 'react-toastify';
import commands, { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import { getError, isLoading } from 'dmpconnectjsapp-base/helpers/common';
import { setESConfiguration, setMssConfiguration, setUserConfiguration } from 'dmpconnectjsapp-base/actions';
import { formatPerformMssAuthentication } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import {
  getMssReplyTo, getMssSenderWording, MssConfigSchema,
} from '../../dmpconnect/helpers/mss';
import { getAction, setPersistedAppConfiguration } from '../../dmpconnect/actions';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import SwitchCheckbox from '../Common/Form/Input/SwitchCheckbox';
import ErrorMessage from '../Common/Message/ErrorMessage';
import env from '../../envVariables';

function MssConfig({
  dispatch, mssEmail, getEmailIsLoading, accessRights, columnLayout, mssActive, config, getEmailError,
  mssSenderWording, mssReplyTo,
  title,
  mssLoginType,
  mssApiType,
  esUser,
  performMssAuthSection,
  mssReceiptNotificationType,

  mssImapServer,
  mssImapPort,
  mssSmtpServer,
  mssSmtpPort,
  esMssCertificate,

  mssImapLoginPasswd,
  mssSmtpLoginPasswd,
}) {
  const [email, setEmail] = React.useState(mssEmail);
  const [rpps, setRpps] = React.useState(esUser ? esUser.hpInternalId : accessRights.psId);
  const [senderWording, setSenderWording] = React.useState(mssSenderWording);
  const [replyTo, setReplyTo] = React.useState(mssReplyTo);
  const [enabled, setEnabled] = React.useState(mssActive);
  const [submitting, setSubmitting] = React.useState(false);
  const [apiType, setApiType] = React.useState(mssApiType);
  const [loginType, setLoginType] = React.useState(mssLoginType);
  const [receiptNotificationType, setReceiptNotificationType] = React.useState(mssReceiptNotificationType);
  const [imapServer, setImapServer] = React.useState(mssImapServer);
  const [imapPort, setImapSPort] = React.useState(mssImapPort);
  const [imapLoginPasswd, setImapLoginPasswd] = React.useState(mssImapLoginPasswd);
  const [smtpLoginPasswd, setSmtpLoginPasswd] = React.useState(mssSmtpLoginPasswd);
  const [smtpServer, setSmtpServer] = React.useState(mssSmtpServer);
  const [smtpPort, setSmtpPort] = React.useState(mssSmtpPort);
  const [mssCertificate, setMssCertificate] = React.useState(esMssCertificate);

  React.useEffect(() => {
    setEmail(mssEmail);
  }, [mssEmail]);

  const submitMss = () => {
    setSubmitting(true);
    dispatch(setUserConfiguration(accessRights.psId, 'mssEmail', email));
    dispatch(setUserConfiguration(accessRights.psId, 'mssSenderWording', senderWording));
    dispatch(setUserConfiguration(accessRights.psId, 'mssReplyTo', replyTo));
    dispatch(setPersistedAppConfiguration('mssActive', enabled));
    dispatch(setPersistedAppConfiguration('mssOtpChannelType', ''));
    dispatch(setMssConfiguration('mssApiType', apiType));
    dispatch(setMssConfiguration('mssLoginType', loginType));
    dispatch(setMssConfiguration('mssReceiptNotificationType', receiptNotificationType));
    dispatch(setMssConfiguration('mssImapServer', imapServer));
    dispatch(setMssConfiguration('mssImapPort', imapPort));
    dispatch(setMssConfiguration('mssImapLoginPasswd', imapLoginPasswd));
    dispatch(setMssConfiguration('mssSmtpServer', smtpServer));
    dispatch(setMssConfiguration('mssSmtpPort', smtpPort));
    dispatch(setMssConfiguration('mssSmtpLoginPasswd', smtpLoginPasswd));
    dispatch(setESConfiguration('esMssCertificate', mssCertificate));

    toast(
      'La configuration MSS à été mise à jour',
      {
        type: 'success',
        position: 'top-right',
        closeOnClick: true,
        autoClose: true,
        hideProgressBar: true,
      },
    );
    setSubmitting(false);
  };

  const performMssAuthentication = () => {
    submitMss();
    dispatch(getAction(
      commands.performMssAuthentication,
      apiSections.MSS_PERFORM_AUTHENTICATION,
      formatPerformMssAuthentication(rpps),
    ));
  };

  const getEmailFromCPS = () => {
    dispatch(setUserConfiguration(accessRights.psId, 'mssEmail', ''));
    setEmail('');
    dispatch(getAction(
      commands.getEmailAdress,
      apiSections.MSS_GET_EMAIL,
      accessRights.psId,
      { silentError: true },
    ));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          {title && (
          <h5 className="mb-4">{title}</h5>
          )}

          {getEmailError && (
          <ErrorMessage
            error={getEmailError}
            message={(
              <>
                L&apos;utilisation de la messagerie MSS nécessite un compte Mailiz.
                <br />
                Si vous n&apos;en disposez pas, vous pouvez en créer un depuis cette adresse :
                {' '}
                <a
                  href={Number(env.REACT_APP_PRODUCTION_MODE) === 1 ? 'https://mailiz.mssante.fr/' : 'https://mailiz.formation.mssante.fr/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailiz
                </a>
              </>
          )}
            showDetails
          />
          )}

          <Form className="flex-grow-1 mr-5">
            {config && (
              <>
                <h6>Configuration technique</h6>
                <Form.Group as={columnLayout ? Row : undefined}>
                  <Form.Label column={columnLayout} sm="4">
                    Activer MSS
                  </Form.Label>
                  <div className={`d-flex v-centered ${columnLayout ? 'col-sm-8' : undefined}`}>
                    <SwitchCheckbox
                      id="mssActive"
                      onChange={event => setEnabled(event.target.checked)}
                      name="mssActive"
                      checked={enabled}
                      showLabel
                      onLabel="Activé"
                      offLabel="Désactivé"
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Mode d&apos;accès
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="mssApiType"
                    onChange={event => setApiType(event.target.value)}
                    value={apiType}
                  >
                    <option value={mssSubTypes.IMAP}>IMAP/SMTP</option>
                    <option value={mssSubTypes.WEB}>Web Services</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Mode d&apos;authentification
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="mssLoginType"
                    onChange={event => setLoginType(event.target.value)}
                    value={loginType}
                  >
                    <option key={mssLoginTypes.CPX} value={mssLoginTypes.CPX}>CPx</option>
                    <option key={mssLoginTypes.CERT} value={mssLoginTypes.CERT}>Certificat logiciel</option>
                    <option key={mssLoginTypes.OTP} value={mssLoginTypes.OTP}>OTP</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Type d&apos;accus&eacute; de r&eacute;ception
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="mssReceiptNotificationType"
                    onChange={event => setReceiptNotificationType(event.target.value)}
                    value={receiptNotificationType}
                  >
                    <option value={mssReceiptNotificationTypes.DSN}>
                      Delivery Status Notification (DSN) (expérimental)
                    </option>
                    <option value={mssReceiptNotificationTypes.MDN}>
                      Message Delivery Notification (MDN)
                    </option>
                  </Form.Control>
                </Form.Group>

                {apiType === mssSubTypes.IMAP && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        Serveur IMAP
                      </Form.Label>
                      <Form.Control
                        onChange={event => setImapServer(event.target.value)}
                        name="mssImapServer"
                        value={imapServer}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Port IMAP
                      </Form.Label>
                      <Form.Control
                        onChange={event => setImapSPort(event.target.value)}
                        name="mssImapPort"
                        value={imapPort}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Imap login
                      </Form.Label>
                      <Form.Control
                        onChange={event => setImapLoginPasswd(event.target.value)}
                        name="mssImapLogin"
                        value={imapLoginPasswd}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Serveur SMTP
                      </Form.Label>
                      <Form.Control
                        onChange={event => setSmtpServer(event.target.value)}
                        name="mssSmtpServer"
                        value={smtpServer}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Port SMTP
                      </Form.Label>
                      <Form.Control
                        onChange={event => setSmtpPort(event.target.value)}
                        name="mssSmtpPort"
                        value={smtpPort}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        Smtp login
                      </Form.Label>
                      <Form.Control
                        onChange={event => setSmtpLoginPasswd(event.target.value)}
                        name="mssSmtpLogin"
                        value={smtpLoginPasswd}
                      />
                    </Form.Group>
                  </>
                )}
                {loginType === mssLoginTypes.CERT && (
                  <Form.Group>
                    <Form.Label>
                      Certificat MSS
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="textarea"
                        onChange={event => setMssCertificate(event.target.value)}
                        name="esMssCertificate"
                        value={mssCertificate}
                      />
                    </Col>
                  </Form.Group>
                )}
                <div className="admin-content-divider" />
              </>
            )}

            <Form.Group as={columnLayout ? Row : undefined}>
              <Form.Label column={columnLayout} sm="4">
                Adresse Email
              </Form.Label>
              <div className={columnLayout ? 'col-sm-8' : undefined}>
                <Form.Control
                  id="mssEmail"
                  disabled={config && !enabled}
                  onChange={event => setEmail(event.target.value)}
                  name="mssEmail"
                  value={email}
                />
              </div>
              {/* <Form.Control.Feedback type="invalid"> */}
              {/* {errors.mssEmail} */}
              {/* </Form.Control.Feedback> */}
            </Form.Group>
            {mssApiType === mssSubTypes.WEB && (
              <Form.Group controlId="rpps">
                <Form.Label>
                  Identifiant national (rpps)
                </Form.Label>
                <Form.Control
                  value={rpps}
                  onChange={event => setRpps(event.target.value)}
                />
              </Form.Group>
            )}
            {!config && (
              <>
                {mssLoginType === mssLoginTypes.CERT && mssApiType === mssSubTypes.WEB ? (
                  <Form.Group>
                    <ButtonWithLoader
                      disabled={!rpps}
                      label="Se connecter"
                      loading={isLoading(performMssAuthSection)}
                      onClick={() => performMssAuthentication()}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <ButtonWithLoader
                      disabled={MssConfigSchema.isValidSync({
                        mssEmail: email,
                      })}
                      label="Se connecter"
                      loading={submitting}
                      onClick={() => submitMss()}
                    />
                  </Form.Group>
                )}
              </>
            )}

            {mssLoginType === mssLoginTypes.CPX && (
              <>
                {!config && <div className="typography-big-text text-gray-300 mb-3">ou</div>}

                <Form.Group>
                  <ButtonWithLoader
                    disabled={config && !enabled}
                    loading={getEmailIsLoading}
                    label="Obtenir l'email depuis la carte CPS"
                    loadingLabel=""
                    variant="outline-secondary"
                    onClick={() => getEmailFromCPS()}
                    className="mr-2"
                  />
                </Form.Group>
              </>
            )}

            {config && (
              <>
                <Form.Group as={columnLayout ? Row : undefined}>
                  <Form.Label column={columnLayout} sm="4">
                    Nom de l&apos;expéditeur
                  </Form.Label>
                  <div className={columnLayout ? 'col-sm-8' : undefined}>
                    <Form.Control
                      id="mssSenderWording"
                      disabled={config && !enabled}
                      onChange={event => setSenderWording(event.target.value)}
                      name="mssSenderWording"
                      value={senderWording}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={columnLayout ? Row : undefined}>
                  <Form.Label column={columnLayout} sm="4">
                    Adresse de réponse
                  </Form.Label>
                  <div className={columnLayout ? 'col-sm-8' : undefined}>
                    <Form.Control
                      id="mssReplyTo"
                      disabled={config && !enabled}
                      onChange={event => setReplyTo(event.target.value)}
                      name="mssReplyTo"
                      value={replyTo}
                    />
                  </div>
                </Form.Group>

                <div className="admin-content-divider" />
                <Form.Group>
                  <ButtonWithLoader
                    disabled={MssConfigSchema.isValidSync({
                      mssEmail: email,
                      // mssImapServer,
                      // mssSmtpServer,
                    })}
                    label="Valider"
                    loading={submitting}
                    onClick={() => submitMss()}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </div>
        <i className="ic-mss" />
      </div>
    </>
  );
}

MssConfig.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getEmailIsLoading: PropTypes.bool,
  getEmailError: PropTypes.object,
  performMssAuthSection: PropTypes.object,
  esUser: PropTypes.object,
  mssEmail: PropTypes.string,
  mssSenderWording: PropTypes.string,
  mssReplyTo: PropTypes.string,
  accessRights: PropTypes.object.isRequired,
  columnLayout: PropTypes.bool,
  mssActive: PropTypes.bool,
  config: PropTypes.bool,
  title: PropTypes.string,
  mssLoginType: PropTypes.string.isRequired,
  mssApiType: PropTypes.string.isRequired,
  mssReceiptNotificationType: PropTypes.string.isRequired,
  mssImapServer: PropTypes.string,
  mssImapPort: PropTypes.string,
  mssSmtpServer: PropTypes.string,
  mssSmtpPort: PropTypes.string,
  esMssCertificate: PropTypes.string,
  mssImapLoginPasswd: PropTypes.string,
  mssSmtpLoginPasswd: PropTypes.string,
};
MssConfig.defaultProps = {
  esUser: undefined,
  getEmailIsLoading: false,
  getEmailError: {},
  performMssAuthSection: {},
  mssEmail: '',
  mssSenderWording: '',
  mssReplyTo: '',
  columnLayout: true,
  mssActive: false,
  config: true,
  title: '',
  mssImapServer: '',
  mssImapPort: '',
  mssSmtpServer: '',
  mssSmtpPort: '',
  esMssCertificate: '',
  mssImapLoginPasswd: '',
  mssSmtpLoginPasswd: '',
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.MSS_GET_EMAIL]: getEmailSection,
      [apiSections.MSS_PERFORM_AUTHENTICATION]: performMssAuth,
    },
    dmpconnectUser: { esUser },
    dmpConnectPersistedAppConfiguration,
    dmpconnectESConfiguration: {
      esMssCertificate,
    },
    dmpconnectMSSConfiguration: {
      mssLoginType,
      mssApiType,
      mssReceiptNotificationType,
      mssImapServer,
      mssImapPort,
      mssSmtpServer,
      mssSmtpPort,
      mssImapLoginPasswd,
      mssSmtpLoginPasswd,
    },
  } = state;

  return {
    getEmailIsLoading: isLoading(getEmailSection),
    getEmailError: getError(getEmailSection),
    mssEmail: getMssEmail(state),
    mssSenderWording: getMssSenderWording(state),
    mssReplyTo: getMssReplyTo(state),
    mssActive: getConfigurationValue('mssActive', dmpConnectPersistedAppConfiguration),
    mssLoginType,
    mssApiType,
    mssReceiptNotificationType,
    mssImapServer,
    mssImapPort,
    mssSmtpServer,
    mssSmtpPort,
    mssImapLoginPasswd,
    mssSmtpLoginPasswd,
    esUser,
    performMssAuthSection: performMssAuth,
    esMssCertificate,
  };
}

export default checkAccessRights(connect(mapStateToProps)(MssConfig));
