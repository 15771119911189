import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading({
  message, children, size, centered,
}) {
  return (
    <div className={centered ? 'centered-container' : 'd-inline-block mr-2'}>
      <Spinner animation="border" size={size} />
      {message !== null && <span className="mt-2">{message}</span>}
      {children}
    </div>
  );
}

Loading.defaultProps = {
  centered: true,
  message: null,
  children: null,
  size: null,
};

Loading.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node,
  message: PropTypes.string,
  size: PropTypes.string,
};
