import { mssReceiptNotificationTypes } from 'dmpconnectjsapp-base/constants';
import { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import env from '../envVariables';

export const connectorConfigFromEnv = {
  applicationId: env.REACT_APP_ID,
  production_mode: Number(env.REACT_APP_PRODUCTON_MODE) === 1,
  dmpcjsProtocol: env.REACT_APP_DMPCONNECTJS_PROTOCOL,
  dmpcjsHttpProtocol: env.REACT_APP_DMPCONNECTJS_HTTPPROTOCOL,
  dmpcjsHost: env.REACT_APP_DMPCONNECTJS_HOST,
  dmpcjsPort: env.REACT_APP_DMPCONNECTJS_PORT,
  dmpcjsVersion: env.REACT_APP_DMPCONNECTJS_VERSION,

  apiType: env.REACT_APP_API_TYPE,
  air: Number(env.REACT_APP_AIR) === 1,

  esRESTHost: env.REACT_APP_ES_REST_HOST,
  esRESTPort: env.REACT_APP_ES_REST_PORT,
  esRestUrl: env.REACT_APP_ES_REST_URL,

  serverName: env.REACT_APP_DMP_SERVER_NAME,
  webPsRootUrl: env.REACT_APP_WEB_PS_BASE_URL,

  dcparams: env.REACT_APP_DCPARAMS,

  debugActivateRemoteControl: Number(env.REACT_APP_REMOTE_CONTROL) === 1,
  forceDesactivateRemoteControl: false,
  tlsiServerName: env.REACT_APP_TLSI_SERVER_NAME,

  consultOnly: Number(env.REACT_APP_CONSULT_ONLY) === 1,
  debugNdp: env.REACT_APP_DP_DEBUG_NDP || null,
  airOnly: Number(env.REACT_APP_AIR_NO_ES) === 1,
  exportCdaDocument: Number(env.REACT_APP_ES_REST_EXPORT_CDA_DOCUMENT) === 1,
};

export const persistedConnectorConfigFromEnv = {
  onlyGeneratedConnectorJWT: Number(env.REACT_APP_GENERATED_CONNECTOR_JWT) === 1,
};

export const esConfigFromEnv = {
  loginType: env.REACT_APP_ES_LOGIN_TYPE || 'OPENID',
  configRequired: Number(env.REACT_APP_ES_CONFIG_NEEDED) === 1,
  hpAuthenticationMode: Number(env.REACT_APP_ES_AUTHENTICATION_MODE) || (Number(env.REACT_APP_AIR) === 1 ? 10 : 8),
  hpAuthenticationContext: Number(env.REACT_APP_ES_AUTHENTICATION_CONTEXT) || 3,
  hpGiven: Number(env.REACT_APP_DEBUG) === 1 ? 'CHARLES' : undefined,
  hpInternalId: Number(env.REACT_APP_DEBUG) === 1 ? '899700245360' : undefined,
  hpName: Number(env.REACT_APP_DEBUG) === 1 ? 'Dr CARDIOCH0024536' : undefined,
  hpProfession: Number(env.REACT_APP_DEBUG) === 1 ? '10' : undefined,
  hpProfessionOid: Number(env.REACT_APP_DEBUG) === 1 ? '1.2.250.1.71.1.2.7' : undefined,
  hpSpeciality: Number(env.REACT_APP_DEBUG) === 1 ? 'SM04' : undefined,
  es_id: env.REACT_APP_ES_ID || null,
  es_id_mss: env.REACT_APP_ES_ID_MSS || null,
  es_finess: env.REACT_APP_ES_FINESS,
  esLoginActive: Number(env.REACT_APP_ES) === 1,
  esCertificate: env.REACT_APP_ES_CERT || null,
  esMssCertificate: env.REACT_APP_ES_CERT_MSS || null,
  practiceLocationName: env.REACT_APP_ES_LOCATION_NAME || null,
  activitySector: env.REACT_APP_ES_ACTIVITY_SECTOR || null,
  practiceSetting: env.REACT_APP_ES_PRACTICE_SETTING || null,
  healthcareSetting: env.REACT_APP_ES_HEALTHCARE_SETTING || null,
  loginApiEndpoint: env.REACT_APP_ES_LOGIN_API_ENDPOINT || null,
  loginApiPSParam: env.REACT_APP_ES_LOGIN_API_ENDPOINT_PS_PARAM || null,
  loginApiPatientParam: env.REACT_APP_ES_LOGIN_API_ENDPOINT_PATIENT_PARAM || null,
  loginApiExtraHeaders: env.REACT_APP_ES_LOGIN_API_EXTRA_HEADER || null,
  loginCheckApi: Number(env.REACT_APP_ES_LOGIN_CHECK_API) === 1,
  loginCheckApiEndpoint: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT || null,
  loginCheckApiPSParam: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PS_PARAM || null,
  loginCheckApiPatientParam: env.REACT_APP_ES_LOGIN_CHECK_API_ENDPOINT_PATIENT_PARAM || null,
  cpxLoginActive: Number(env.REACT_APP_ES_CPX_LOGIN_ACTIVE) === 1,
};

export const tseConfigFromEnv = {
  active: Number(env.REACT_APP_TSE) === 1,
};

export const dpConfigFromEnv = {
  serverName: env.REACT_APP_DP_SERVER_NAME || null,
  etabType: env.REACT_APP_DP_ETAB_TYPE || null,
  etabIdentifier: env.REACT_APP_DP_ETAB_IDENTIFIER || null,
  softwareValidationCode: env.REACT_APP_DP_SOFTWARE_VALIDATION_CODE || null,
  softwareVersion: env.REACT_APP_DP_SOFTWARE_VERSION || null,
  softwarePatch: env.REACT_APP_DP_SOFTWARE_PATCH || null,
};

export const aldConfigFromEnv = {
  active: Number(env.REACT_APP_ALD) === 1,
  idam: env.REACT_APP_ALD_ID_AM,
  numAm: env.REACT_APP_ALD_NUM_AM,
  lpsName: env.REACT_APP_ALD_LPS_NAME,
  lpsVersion: env.REACT_APP_ALD_LPS_VERSION,
};

export const insiConfigFromEnv = {
  active: Number(env.REACT_APP_INSI) === 1,
  idam: env.REACT_APP_INSI_ID_AM,
  numAm: env.REACT_APP_INSI_NUM_AM,
  lpsName: env.REACT_APP_INSI_LPS_NAME,
  lpsVersion: env.REACT_APP_INSI_LPS_VERSION,
};

export const apCvConfigFromEnv = {
  active: Number(env.REACT_APP_APCV) === 1,
  serverName: env.REACT_APP_APCV_SERVER_NAME,
  idam: env.REACT_APP_APCV_ID_AM,
  numAm: env.REACT_APP_APCV_NUM_AM,
  lpsName: env.REACT_APP_APCV_LPS_NAME,
  lpsVersion: env.REACT_APP_APCV_LPS_VERSION,
  startDate: env.REACT_APP_APCV_START_DATE,
};

export const mssConfigFromEnv = {
  mssLoginType: env.REACT_APP_MSS_LOGIN_TYPE,
  mssReceiptNotificationType: env.REACT_APP_MSS_RECEIPT_NOTIFICATION_TYPE || mssReceiptNotificationTypes.MDN,
  mssApiType: env.REACT_APP_MSS_API_TYPE || mssSubTypes.IMAP,

  mssImapServer: env.REACT_APP_MSS_IMAP_SERVER,
  mssSmtpServer: env.REACT_APP_MSS_SMTP_SERVER,

  mssImapPort: env.REACT_APP_MSS_IMAP_PORT,
  mssSmtpPort: env.REACT_APP_MSS_SMTP_PORT,
};
