import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import 'react-table-6/react-table.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import './scss/main.scss';

import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';

import App from './App';
import { unregister } from './registerServiceWorker';
import configureStore, { history, sagaMiddleware } from './store';
import rootSaga from './dmpconnect/sagas/rootSaga';
import env from './envVariables';

// new Event polyfill for IE11
(function () {
  if (typeof window.Event === 'function') return false; // If not IE

  function CustomEvent(event, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.Event = CustomEvent;
}());

console.log = (function () {
  const originalFunc = console.log;
  const logLevel = Number(env.REACT_APP_LOG_LEVEL);
  if (logLevel === 0) {
    return function () {
    };
  }
  return originalFunc;
}());


unregister();

export const { store, persistor } = configureStore();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <LastLocationProvider>
          <App history={history} />
        </LastLocationProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);

sagaMiddleware.run(rootSaga);
